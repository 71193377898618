import { env } from "@/env";
import {
  calculateHoursLeft,
  checkIfTBD,
  createDirectPaymentIntentURL,
  hasEventEnded,
  isObjectEmptyOrNull,
  preparePatchRequestObject,
  preparePaymentRequestObject,
  redirectToSignIn,
  sessionParticipationUpdationHandler
} from "@/lib/utils";
import {
  IPaymentIntent,
  MonetaryTypeDetails,
  SessionParticipationPayload
} from "@/types/cusom-types";

import { useDisclosure } from "@mantine/hooks";
import { IconArrowUpRight } from "@tabler/icons-react";
import dayjs from "dayjs";
import _ from "lodash";
import { useSession } from "next-auth/react";
import { useParams, useRouter, useSearchParams } from "next/navigation";
import React, { useEffect, useMemo, useState } from "react";
import {
  EVENT_JOIN_STATUS,
  PAYMENT_SUCCESS_STATUS,
  PRICE_UNITS_LIST,
  ROLES
} from "@key.ai/constants";
import eventClientApi from "@/api-client/event-client";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Text } from "@/components/ui/text";
import Multiline from "@/components/ui/multiline";
import { Ticket, Timer, UserTick } from "iconsax-react";
import IconContainer from "@/components/ui/icon-container";
import Divider from "@/components/ui/divider";
import Avatar from "@/components/ui/avatar";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "@/components/ui/dialog";
import { toast } from "sonner";
import QuestionState from "./questionaire";
import { Loader } from "@/components/ui/loader";
import EventPayment from "./event-payment";
import { useModal } from "@/stores/use-modal-store";
import ConfettiExplosion from "react-confetti-explosion";
import { cleanObject } from "@/lib/helper";
import Box from "@/components/ui/box";
import { Skeleton } from "@/components/ui/skeleton";

type ActiveRingColor =
  | "teal"
  | "green"
  | "grey"
  | "yellow"
  | "purple"
  | "steel"
  | "red"
  | undefined;
type PresenceState = "available" | "offline" | "away" | "blocked" | "unknown" | "busy" | undefined;

const prepareJoinSessionsCreationUrl = (userId: string, eventId: string, passcode?: string) =>
  `api/v2/users/${userId}/events/${eventId}/participations${
    passcode ? "?passcode=" + passcode : ""
  }`;

export const searchDirectPaymentIntentURL = (userId: any, eventId: string, ticketId: string) =>
  `api/v3/users/${userId}/events/${eventId}/stripe-payment-intent/search/${ticketId}`;

export const getTicketPrice = (ticket: MonetaryTypeDetails, platformFee: string) => {
  if (ticket?.application_fee_absorb) {
    return ticket.price;
  }
  const platform_fee_percentage = Number(platformFee) ? Number(platformFee) / 100 : 0.05;
  return Number(Number(ticket?.price) + Number(ticket?.price) * platform_fee_percentage).toFixed(2);
};

export const getCurrencyInfo = (currency: string) => {
  return PRICE_UNITS_LIST.find((price) => price.value === currency) || PRICE_UNITS_LIST[0];
};

const EventJoinView = ({ eventData, setGuestStatus, fetchProxyEventData }: any) => {
  const { event } = eventData;

  const { onOpen } = useModal();

  const [participationDetails, setParticipationDetails] = useState<{
    id?: string;
    role?: string;
    type?: string;
    status: string;
    ticket_id?: string;
    meta?: Record<string, any>;
  }>({ id: "", role: "", type: "", status: "" });

  const [startsIn, setStartsIn] = useState<{
    timeString: string;
    status: "upcoming" | "ongoing" | "ended";
  }>({ timeString: "", status: "upcoming" });
  const [disableRegister, setDisableRegister] = useState(false);
  const [showPayments, setShowPayments] = useState<boolean>(false);

  const [joiningSessions, setJoiningSessions] = useState<
    SessionParticipationPayload[] | null | undefined
  >(sessionParticipationUpdationHandler(eventData?.event_sessions, "going"));

  const [opened, { open: openCancelModal, close: closeCancelModal }] = useDisclosure(false);

  const [sessionParticipations, setSessionParticipations] = useState<any[]>();
  const [showPaymentElement, setShowPaymentElement] = useState<any>(false);

  const [paymentIntent, setPaymentIntent] = useState<IPaymentIntent | null>();
  const [updatingParticipationStatus, setUpdatingParticipationStatus] = useState<boolean>(false);

  const router = useRouter();

  const params = useParams();

  const { data: user, status: authStatus } = useSession();
  const value = useSession();
  const searchParams = useSearchParams();

  let userId = user?.user.id as string;
  const [selectedTicket, setSelectedTicket] = useState<MonetaryTypeDetails | null>();
  const [showQuestionaire, setShowQuestionaire] = useState<any>(false);
  const [questions, setQuestions] = useState<any>([]);
  const [queResponses, setQueresponses] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [participationLoader, setParticipationLoader] = useState<any>(false);

  const [isExploding, setIsExploding] = useState(false);

  // api calls starts
  const fetchOriginalEventData = async () => {
    setParticipationLoader(true);
    try {
      const resp = await eventClientApi
        .get(prepareJoinSessionsCreationUrl(userId, event.id))
        .json<any>();
      const { event_participation, session_participation } = resp?.data || {};

      setDisableRegister(
        !isObjectEmptyOrNull(event_participation) && event_participation?.role === ROLES.CREATOR
      );
      setParticipationDetails({ ...(event_participation || {}) });
      setGuestStatus({ status: event_participation?.status, role: event_participation?.role });
      setSessionParticipations([...(session_participation || [])]);
    } catch (err: any) {
      if (!err) {
        return;
      }
      const { status, data } = err?.response;
      if (status === 404) {
        // toast(data.message);
        // router.push(PROTECTED_ROUTE_SLUG.BP_MY_EVENTS);
      } else {
        // handleApiErrors(err, router, signOut, false, true);
      }
    } finally {
      setParticipationLoader(false);
    }
  };

  useEffect(() => {
    if (userId && event?.id) {
      fetchOriginalEventData();
    }
  }, [event?.id, userId]);

  useEffect(() => {
    let interval: any = null;
    if (
      event?.start_epoc &&
      event?.end_epoc &&
      participationDetails.status === EVENT_JOIN_STATUS.GOING
    ) {
      interval = setInterval(() => {
        setStartsIn(calculateHoursLeft(Number(event.start_epoc), Number(event.end_epoc)));
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [event, participationDetails.status]);

  const handleCancelClick = () => {
    openCancelModal();
  };

  const tickets = (event?.monetary_type_details || [])
    .filter((ticket: MonetaryTypeDetails) => ticket.id && !ticket.is_deleted)
    .sort(
      (a: MonetaryTypeDetails, b: MonetaryTypeDetails) =>
        (a.ticket_order || 0) - (b.ticket_order || 0)
    );

  useEffect(() => {
    if (tickets.length === 1) {
      setSelectedTicket(tickets[0]);
    }
  }, [tickets]);

  const showTickets = () =>
    ![ROLES.FEATURED_GUEST, ROLES.HOST].includes(participationDetails.role || "") &&
    (!participationDetails.status ||
      [
        EVENT_JOIN_STATUS.NOT_GOING,
        EVENT_JOIN_STATUS.PENDING,
        EVENT_JOIN_STATUS.PAYMENT_PENDING,
        EVENT_JOIN_STATUS.PAYMENT_PROCESSING,
        EVENT_JOIN_STATUS.PAYMENT_FAILED
      ].includes(participationDetails.status)) &&
    tickets.filter((ticket: MonetaryTypeDetails) => !ticket.is_default).length;

  const needPayment = () => event?.monetary_type === "paid" && showTickets();

  useEffect(() => {
    if (event?.id && userId)
      if (selectedTicket?.id) {
        sessionStorage.setItem(`${event.id}_${userId}_ticket`, selectedTicket.id);
      } else {
        sessionStorage.removeItem(`${event.id}_${userId}_ticket`);
      }
  }, [selectedTicket]);

  const getTicket = () => {
    const storedTicketId = sessionStorage.getItem(`${event.id}_${userId}_ticket`);
    const ticket = event.monetary_type_details.find((item: MonetaryTypeDetails) =>
      participationDetails.status === EVENT_JOIN_STATUS.GOING
        ? item.id === participationDetails.ticket_id
        : item.id === storedTicketId
    );

    if (ticket) {
      return ticket;
    } else if (
      [EVENT_JOIN_STATUS.PENDING, EVENT_JOIN_STATUS.NOT_GOING, EVENT_JOIN_STATUS.REVOKE].includes(
        participationDetails.status || ""
      ) ||
      !participationDetails.status
    ) {
      return event.monetary_type_details.find((item: MonetaryTypeDetails) => item.is_default);
    }
  };

  const getPaymentInfo = async () => {
    const selectedTicket = getTicket();
    try {
      if (
        selectedTicket &&
        selectedTicket?.type === "paid" &&
        ![ROLES.FEATURED_GUEST, ROLES.HOST].includes(participationDetails.role || "")
      ) {
        let paymentIntentArray = [];
        const response = await eventClientApi
          .get(searchDirectPaymentIntentURL(userId, event.id || "", selectedTicket.id))
          .json<any>();

        if (response.status === "success") {
          paymentIntentArray.push(response?.data);
        }
        const succeededPaymentInfo = paymentIntentArray.find(
          (paymentIntent: IPaymentIntent) => paymentIntent.status === PAYMENT_SUCCESS_STATUS
        );
        let status = true;
        if (
          !succeededPaymentInfo &&
          (!participationDetails.status ||
            [EVENT_JOIN_STATUS.PENDING, EVENT_JOIN_STATUS.NOT_GOING].includes(
              participationDetails.status
            ))
        ) {
          // toast({
          //   title: "Kindly proceed with the payment to complete the registration process.",
          //   variant: "danger"
          // });

          //   notifications.show({
          //     message:
          //       "Kindly proceed with the payment to complete the registration process.",
          //   });
          status = false;
        }
        return {
          status,
          paymentIntent: succeededPaymentInfo || null
        };
      } else {
        return { status: true, paymentIntent: null };
      }
    } catch (err: any) {
      //   if (err?.request || err?.response) {
      //     handleApiErrors(err, router, signOut, false, true);
      //   }
      return { status: false, paymentIntent: null };
    }
  };

  const updateUserPreference = (actualSessions: any, userPreferredSessions: any) => {
    const updatedArray = _.map(actualSessions, (session) => {
      const matchingSession = _.find(userPreferredSessions, {
        event_session_id: session.id
      });
      const sessionParticipation = _.find(sessionParticipations, {
        session_id: session.id
      });
      return matchingSession
        ? {
            ...session,
            status: matchingSession.status,
            participation_details: {
              id: sessionParticipation?.id
            }
          }
        : session;
    });
    return updatedArray;
  };

  const handleCreatePaymentIntent = async (participationId?: string) => {
    const intentPayload: any = {
      ticket_id: selectedTicket?.id
    };
    if (participationId) {
      intentPayload.paymentIntent = participationId;
    }
    try {
      const response = await eventClientApi
        .post(createDirectPaymentIntentURL(userId, event.id || ""), {
          json: { ...intentPayload }
        })
        .json<any>();
      if (response.data?.client_secret) {
        setPaymentIntent(response.data);
        if (response.data?.status === PAYMENT_SUCCESS_STATUS) {
          handleUpdateParticipationStatus(EVENT_JOIN_STATUS.GOING);
        } else {
          setShowPaymentElement(true);
        }
      }
    } catch (err: any) {
      // handleApiErrors(err, router, signOut, false, true);
    }
  };

  const handleUpdateParticipationStatus = async (status: string, paymentIntent?: string) => {
    setUpdatingParticipationStatus(true);
    let modifiedSessions = [...eventData?.event_sessions];
    let joiningSessions = sessionParticipationUpdationHandler(eventData.event_sessions, "going");
    if (joiningSessions?.length) {
      modifiedSessions = updateUserPreference(eventData?.event_sessions, joiningSessions);
    }

    try {
      // const { status: paymentStatus, paymentIntent } = await getPaymentInfo();
      const payload = paymentIntent
        ? preparePaymentRequestObject(modifiedSessions, status, paymentIntent, getTicket()?.id)
        : preparePatchRequestObject(modifiedSessions, status, getTicket()?.id);

      const joinResponse = await eventClientApi
        .patch(`api/v2/users/${userId}/events/${event?.id}/participations`, {
          json: { ...payload }
        })
        .json<any>();

      if (joinResponse?.data?.passcode_required) {
        // router.refresh();
      } else {
        if (joinResponse?.status === "success") {
          // notifications.show({ message: joinResponse?.message });
          // toast({ title: joinResponse?.message, variant: "danger" });
          if (status === EVENT_JOIN_STATUS.GOING) setIsExploding(true);
        }
        setShowPayments(false);
        sessionStorage.removeItem(`${event.id}_${userId}_ticket`);
        await fetchOriginalEventData();
        await fetchProxyEventData();
      }
    } catch (error: any) {
      console.log(error);
      if (error?.request || error?.response) {
        // handleApiErrors(error, router, signOut, false, true);
      } else {
        console.error(error);
      }
    } finally {
      setUpdatingParticipationStatus(false);
    }
  };

  const createParticipation = async (status: string, paymentIntent?: string) => {
    setUpdatingParticipationStatus(true);
    // const data = {
    //   status,
    //   eventId: eventData.id
    // };
    const data = {
      session_participation: [
        { event_session_id: eventData?.event_sessions[0]?.id, status: "going" }
      ]
    };
    try {
      const paymentInfo: { paymentIntentId?: string; ticket?: string } = {
        ticket: getTicket()?.id
      };
      if (paymentIntent) {
        paymentInfo.paymentIntentId = paymentIntent;
      }
      const passcode = sessionStorage.getItem(`${eventData?.event?.alias}_${user?.user?.id}`) ?? "";
      const createResp = await eventClientApi
        .post(`api/v2/users/${userId}/events/${event.id}/participations`, {
          searchParams: cleanObject({
            passcode: passcode
          }),
          json: {
            ...data,
            ...paymentInfo
            // userId: userId
          }
        })
        .json<any>();
      if (createResp?.message) {
        if (status === EVENT_JOIN_STATUS.GOING) setIsExploding(true);

        // handleShowConfetti();
        //   notifications.show({ message: createResp?.message });
        // toast({ title: createResp?.message, variant: "danger" });
      }
      // clearSessionStorage();
      await fetchOriginalEventData();
      await fetchProxyEventData();
    } catch (error: any) {
      if (error?.request || error?.response) {
        // handleApiErrors(error, router, signOut, false, true);
      } else {
        console.error(error);
      }
    } finally {
      setUpdatingParticipationStatus(false);
    }
  };

  const createPaidParticipation = async (status: string, paymentIntent?: string) => {
    setUpdatingParticipationStatus(true);
    const data = {
      status,
      eventId: eventData.id,
      ticket: getTicket()?.id
    };
    try {
      const createResp = await eventClientApi
        .post(`api/v2/users/${userId}/events/${event.id}/participations`, {
          json: {
            ...data,
            userId: userId,
            passcode: event?.passcode
          }
        })
        .json<any>();
      if (createResp?.message === "success") {
        handleCreatePaymentIntent(createResp.data.id);
      }
    } catch (error: any) {
      if (error?.request || error?.response) {
        // handleApiErrors(error, router, signOut, false, true);
      } else {
        console.error(error);
      }
    } finally {
      setUpdatingParticipationStatus(false);
    }
  };

  const handleRegisterClick = async () => {
    setLoading(true);
    const { status, data, submitted } = await getQuestionaire();
    // flag to check i  f questioanire is submitted or not
    if (
      status &&
      data?.length > 0 &&
      participationDetails?.status !== EVENT_JOIN_STATUS.NOT_GOING &&
      !submitted
    ) {
      const newData = data?.map((que: any, index: any) => {
        return { ...que, isOpen: true };
      });
      setShowQuestionaire(true);
      setQuestions(newData);
      setLoading(false);
      return;
      // const data = await questionaireResponse([]);
    }
    if (participationDetails.id) {
      handleUpdateParticipationStatus(EVENT_JOIN_STATUS.GOING);
    } else {
      createParticipation(EVENT_JOIN_STATUS.GOING);
    }
    setLoading(false);
  };

  const getQuestionaire = async () => {
    try {
      const resp = await eventClientApi.get(`api/v1/events/${event?.id}/questions`).json<any>();
      if (resp.data) {
        return { status: true, data: resp.data, submitted: resp?.response_given };
      } else {
        return { status: false };
      }
    } catch (err: any) {
      toast.error("error occurred while getting Questionnaire");
      return { status: false };
    }
  };

  const questionaireResponse = async () => {
    setLoading(true);
    try {
      if (queResponses && queResponses.length > 0) {
        const payload = queResponses.filter((item: any) =>
          item.response.response_text.some((text: string) => text.trim() !== "")
        );
        const resp = await eventClientApi.post(
          `api/v1/events/${event.id}/questionnaire-responses`,
          {
            json: { question_responses: [...payload] }
          }
        );
      }
      setShowQuestionaire(false);

      if (selectedTicket?.type === "paid") return handleInitiatePayment(participationDetails?.id);

      if (participationDetails.id) {
        handleUpdateParticipationStatus(EVENT_JOIN_STATUS.GOING);
      } else {
        createParticipation(EVENT_JOIN_STATUS.GOING);
      }
    } catch (err: any) {
      toast.error("error occurred while getting Questionnaire");
      return { status: false };
    } finally {
      setLoading(false);
    }
  };

  const handleProcesspayment = async ({
    paymentIntent,
    participationId
  }: {
    paymentIntent: string;
    participationId: string;
  }) => {
    if (participationId) {
      handleUpdateParticipationStatus(EVENT_JOIN_STATUS.GOING, paymentIntent);
    } else {
      createParticipation(EVENT_JOIN_STATUS.GOING, paymentIntent);
    }
  };

  useEffect(() => {
    const checkRegister = window.localStorage.getItem("registerd");
    if (
      checkRegister &&
      JSON.parse(checkRegister) &&
      (participationDetails?.status === EVENT_JOIN_STATUS.PENDING || !participationDetails?.status)
    ) {
      if (authStatus !== "authenticated") {
        redirectToSignIn({
          callbackUrl: event?.alias ? `/${event.alias}` : "/home"
        });
        window.localStorage.setItem("registerd", JSON.stringify(true));
        const params = new URLSearchParams(searchParams);
        if (selectedTicket?.id) {
          params.set("ticket", selectedTicket.id);
        }
      } else {
        handleRegisterEvent();
        window.localStorage.removeItem("registerd");
      }
    }
  }, []);

  const handleRegisterEvent = async () => {
    if (showTickets() && !selectedTicket?.id) {
      toast.error("Choose a ticket to register");
      return;
    }
    if (authStatus !== "authenticated") {
      redirectToSignIn({
        callbackUrl: event?.alias ? `/${event.alias}` : "/home"
      });
      window.localStorage.setItem("registerd", JSON.stringify(true));
      const params = new URLSearchParams(searchParams);
      if (selectedTicket?.id) {
        params.set("ticket", selectedTicket.id);
      }
    } else if (selectedTicket?.type === "paid") {
      if (selectedTicket.booked_slots && selectedTicket.booked_slots === selectedTicket.capacity) {
        toast.error(`Ticket ${selectedTicket?.name || ""} is sold out.`);
      } else {
        const { status, data, submitted } = await getQuestionaire();
        // flag to check i  f questioanire is submitted or not
        if (
          status &&
          data?.length > 0 &&
          participationDetails?.status !== EVENT_JOIN_STATUS.NOT_GOING &&
          !submitted
        ) {
          const newData = data?.map((que: any, index: any) => {
            return { ...que, isOpen: true };
          });
          setShowQuestionaire(true);
          setQuestions(newData);
          setLoading(false);
          return;
        }
        handleInitiatePayment(participationDetails?.id);
      }
    } else {
      handleRegisterClick();
    }
  };

  const mapPatricipationIdsToSession = (
    sessionParticipations: any,
    sessions: any,
    status: string
  ) => {
    const sessionWithParticipations = _.map(sessions, (session: any) => {
      let sessionParticipation = _.find(sessionParticipations, {
        session_id: session.id
      });
      return {
        ...session,
        participation_details: {
          id: sessionParticipation?.id
        },
        status
      };
    });
    return sessionWithParticipations || sessions;
  };

  const handleCancelStatus = async (status: string) => {
    setUpdatingParticipationStatus(true);

    const payloadSessions = mapPatricipationIdsToSession(
      sessionParticipations,
      eventData?.event_sessions,
      status
    );
    try {
      const { status: paymentStatus, paymentIntent } = await getPaymentInfo();
      if (paymentStatus) {
        const payload = paymentIntent
          ? preparePaymentRequestObject(payloadSessions, status, paymentIntent?.id, getTicket()?.id)
          : preparePatchRequestObject(payloadSessions, status, getTicket()?.id);
        const cancelResponse = await eventClientApi
          .patch(`api/v2/users/${userId}/events/${event?.id}/participations`, {
            json: { ...payload }
          })
          .json<any>();

        if (cancelResponse?.data?.passcode_required) {
          // router.refresh();
        } else {
          // clearSessionStorage();
          if (cancelResponse?.message) {
            toast.success(cancelResponse?.message);
          }
          fetchOriginalEventData();
          await fetchProxyEventData();
        }
      }
    } catch (error: any) {
      if (error?.request || error?.response) {
        // handleApiErrors(error, router, signOut, false, true);
      } else {
        console.error(error);
      }
    } finally {
      setUpdatingParticipationStatus(false);
    }
  };

  const handleManageStatus = async (status: string) => {
    try {
      const payloadSessions = mapPatricipationIdsToSession(
        sessionParticipations,
        eventData?.event_sessions,
        status
      );

      const payload = preparePatchRequestObject(payloadSessions, status, getTicket()?.id);

      await eventClientApi
        .patch(`api/v2/users/${userId}/events/${event?.id}/payment-participations`, {
          json: {
            ...payload
          }
        })
        .json<any>();
      fetchOriginalEventData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleInitiatePayment = async (participationId?: string) => {
    let _participationId = participationId;
    setLoading(true);

    try {
      if (_participationId) {
        await handleManageStatus(EVENT_JOIN_STATUS.PAYMENT_PENDING);
      } else {
        const create = await eventClientApi
          .post(`api/v2/users/${userId}/events/${event?.id}/payment-participations`, {
            json: {
              session_participation: [
                {
                  event_session_id: eventData?.event_sessions[0]?.id,
                  status: EVENT_JOIN_STATUS.PAYMENT_PENDING
                }
              ],
              ticket: getTicket()?.id,
              passcode: eventData.passcode
            }
          })
          .json<any>();

        _participationId = create.data.id;
      }
      onOpen("stripePayment", {
        eventData,
        selectedTicket,
        participationId: _participationId,
        callback: handleProcesspayment,
        manageStatus: handleManageStatus,
        refetch: fetchOriginalEventData
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getRegisterText = () => {
    switch (participationDetails.status) {
      // switch (EVENT_JOIN_STATUS.GOING) {
      case EVENT_JOIN_STATUS.GOING:
        const showCancel = true;
        return {
          title: "Congrats, you are in!",
          description: `A confirmation email has been sent to ${user?.user?.email || ""}`,
          button: showCancel && {
            text: "Cancel",
            color: "bg-[#f31260]",
            loadingText: "Canceling..."
          },
          ringColor: "green",
          presence: "available",
          handleClick: handleCancelClick
        };
      // not working , there might be other conditions
      case EVENT_JOIN_STATUS.NOT_GOING:
        return {
          title: "We’ve canceled your registration!",
          description: "We hope to see you next time! Changed your mind? Join again",
          button: {
            text: "Join",
            color: "bg-primary",
            loadingText: "Joining..."
          },
          ringColor: "offline",
          presence: "red",
          handleClick: handleRegisterEvent
        };
      //  verified but not there in figma
      case EVENT_JOIN_STATUS.WAITLIST:
        return {
          d_title: "Waitlist",
          title: "Waitlist",
          description:
            "Your request to join is currently in a waitlist. You will be informed once it is confirmed.",
          d_icon: "/assets/pending.svg",
          ringColor: "yellow",
          presence: "away"
        };
      //  need to check with ankit and parth
      case EVENT_JOIN_STATUS.REVOKE:
        return {
          d_title: "Cancelled",
          description: "Your registration has been canceled",
          d_icon: "/assets/not-going.svg",
          ringColor: "red",
          presence: "offline"
        };
      // verified the first card / need to work on second card
      case EVENT_JOIN_STATUS.REJECT:
        return {
          d_title: "Rejected",
          title: "Rejected",
          description: participationDetails?.meta?.event_reject_reason
            ? `Your join request is declined by the event host because of the following reason: ${participationDetails.meta.event_reject_reason}`
            : "Your join request has been rejected.",
          d_icon: "/assets/not-going.svg",
          ringColor: "red",
          presence: "blocked"
        };
      // verified
      case EVENT_JOIN_STATUS.GOING_ON_APPROVAL:
        return {
          title: "Pending Approval",
          d_title: "Pending Approval",
          description: "Registration is pending approval",
          d_icon: "/assets/pending.svg",
          ringColor: "yellow",
          presence: "away"
        };
      default:
        const buttonText =
          participationDetails.id && participationDetails.status === EVENT_JOIN_STATUS.PENDING
            ? "Accept Invitation"
            : "Register";
        return {
          title: "Registration",
          description: needPayment()
            ? "This is a paid event. Please proceed to payment to register for the event."
            : participationDetails.id && participationDetails.status === EVENT_JOIN_STATUS.PENDING
              ? "Welcome! To join the event, please accept the invitation from below."
              : "Welcome! To join the event, please register below.",
          button: {
            text: selectedTicket?.type === "paid" ? `Pay and ${buttonText}` : buttonText,
            color: "bg-primary",
            loadingText: "Registering..."
          },
          metaText:
            EVENT_JOIN_STATUS.PAYMENT_PROCESSING === participationDetails.status
              ? "Your previous payment is under processing"
              : EVENT_JOIN_STATUS.PAYMENT_FAILED === participationDetails.status
                ? "Your previous payment has failed"
                : null,
          handleClick: handleRegisterEvent
        };
    }
  };

  const displayTicketOptions = () => {
    return tickets.map((ticket: MonetaryTypeDetails, index: number) => {
      const isSelected = selectedTicket?.id === ticket.id;
      return ticket?.is_deleted ? null : tickets.length === 1 ? (
        <>
          <Multiline
            icon={
              <IconContainer as="div" size="default">
                <Ticket size="28" className="text-color-secondary" />
              </IconContainer>
            }
            titleText={<Text variant={"caption_one"}>{ticket.name}</Text>}
            text1={
              <Text variant={"body_two_strong"}>
                {ticket.price === "0"
                  ? "Free"
                  : `${getTicketPrice(ticket, eventData?.platformFee || "")} ${
                      getCurrencyInfo(ticket.currency)?.label || ticket.currency
                    }`}
              </Text>
            }
          ></Multiline>
          <Divider variant="noMargin" />
        </>
      ) : (
        <div key={index} className={`${ticket.is_disabled ? "opacity-60" : "opacity-100"}`}>
          <div
            className={`flex items-center justify-between py-m px-m rounded-4 mt-s   opa ${
              isSelected ? "bg-[#2C53CA0D]" : ""
            }`}
            key={`displayTicketOptions${ticket.id}${ticket.name}`}
          >
            <div className="flex gap-m items-center">
              <Ticket size="28" className="mx-s text-color-secondary" />
              <div>
                <Text variant={"subtitle_two_strong"} style={{ overflowWrap: "anywhere" }}>
                  {ticket.name}
                </Text>

                <Text className="font-semibold" variant={"caption_one_strong"}>
                  {ticket.price === "0"
                    ? "Free"
                    : `${getTicketPrice(ticket, eventData?.platformFee || "")} ${
                        getCurrencyInfo(ticket.currency)?.label || ticket.currency
                      }`}
                </Text>
              </div>
            </div>
            <div className="flex">
              <Checkbox
                checked={isSelected}
                variant="rounded"
                size="small"
                disabled={ticket.is_disabled || disableRegister || ticket.is_full}
                onCheckedChange={() => {
                  if (!ticket.is_disabled) {
                    setSelectedTicket(() => {
                      return ticket;
                    });
                  }
                }}
                className="mr-s"
              />
            </div>
          </div>
          {ticket.is_disabled && (
            <div className="text-xs font-normal text-zinc-300 my-2">
              This ticket option is no longer available
            </div>
          )}
        </div>
      );
    });
  };

  const isValidPresenceState = (state: any): state is PresenceState => {
    return ["available", "offline", "away", "blocked", "unknown", "busy"].includes(state);
  };

  const isValidColor = (color: any): color is ActiveRingColor => {
    return ["teal", "green", "grey", "yellow", "purple", "steel", "red"].includes(color);
  };

  const displayRegister = () => {
    const {
      title,
      description,
      button,
      handleClick,
      d_icon,
      d_title,
      ringColor,
      presence,
      metaText
    } = getRegisterText();
    let isEnded = false;

    if (event?.end_epoc) {
      const epoc = !isNaN(event.end_epoc) ? parseInt(event.end_epoc + "", 10) : null;

      if (typeof epoc === "number") {
        const diff = dayjs.unix(epoc).diff(dayjs(), "milliseconds");
        if (diff <= 0) {
          isEnded = true;
        }
      }
    }

    const registrationClosed =
      (event?.close_registration || isEnded) &&
      ([EVENT_JOIN_STATUS.PENDING, EVENT_JOIN_STATUS.NOT_GOING].includes(
        participationDetails.status
      ) ||
        !participationDetails.status);
    if (loading) {
      <Loader />;
    }

    return (
      <Box className=" rounded-4 p-l">
        {participationDetails.status !== EVENT_JOIN_STATUS.GOING ? (
          <div className="">
            <Text variant={"title_three"}>Registration</Text>
            <Divider />
          </div>
        ) : null}

        {!participationDetails?.status ||
        participationDetails?.status === EVENT_JOIN_STATUS.PENDING ||
        participationDetails?.status === EVENT_JOIN_STATUS.PAYMENT_PENDING ||
        participationDetails?.status === EVENT_JOIN_STATUS.PAYMENT_FAILED ||
        participationDetails?.status === EVENT_JOIN_STATUS.NOT_GOING ||
        participationDetails?.status === EVENT_JOIN_STATUS.PAYMENT_PROCESSING ? (
          <Text variant={"body_one"} className="mt-m">
            {description}
          </Text>
        ) : null}

        {[
          EVENT_JOIN_STATUS.REJECT,
          EVENT_JOIN_STATUS.GOING_ON_APPROVAL,
          EVENT_JOIN_STATUS.WAITLIST,
          EVENT_JOIN_STATUS.REVOKE
        ].includes(participationDetails.status) && participationDetails.role !== ROLES.CREATOR ? (
          <>
            <Multiline
              titleText={<Text variant={"body_two"}>{title}</Text>}
              text1={<Text variant={"body_one"}>{description}</Text>}
              className="mt-m"
              icon={
                <>
                  <Avatar
                    size={56}
                    imgSrc={`${user?.user?.imageUrl || "https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"}`}
                    variant="image"
                    presence={true}
                    presenceState={isValidPresenceState(presence) ? presence : undefined}
                    activeRing={true}
                    present={true}
                    activeRingColor={isValidColor(ringColor) ? ringColor : undefined}
                  />
                </>
              }
            />
          </>
        ) : (
          <>
            {event?.require_approval && participationDetails.status !== EVENT_JOIN_STATUS.GOING ? (
              <div className="mt-m">
                <Multiline
                  icon={<UserTick size="32" className="text-color-secondary" />}
                  titleText={<Text variant={"body_one"}>Request to join</Text>}
                  text1={
                    <Text variant={"caption_one"} className="whitespace-nowrap">
                      Your registration is subject to approval by the host.
                    </Text>
                  }
                />
                <Divider />
              </div>
            ) : (
              <></>
            )}
            {showTickets() || ROLES.CREATOR === participationDetails.role ? (
              <div>{displayTicketOptions()}</div>
            ) : null}
          </>
        )}

        {participationDetails.status === EVENT_JOIN_STATUS.GOING &&
          participationDetails.role !== ROLES.CREATOR && (
            <div className="rounded-4 p-2 mb-2">
              {event.start_epoc && event.time_zone && (
                <div className="flex flex-col">
                  <div className="flex gap-m items-start">
                    <Avatar
                      size={40}
                      imgSrc={`${user?.user?.imageUrl || "https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"}`}
                      variant="image"
                      presence={true}
                      presenceState={isValidPresenceState(presence) ? presence : undefined}
                      activeRing={true}
                      present={true}
                      activeRingColor={isValidColor(ringColor) ? ringColor : undefined}
                      className="!border-[2px]"
                    />
                    <div className="">
                      <Text variant={"body_two_strong"}>{title}</Text>
                      <Text variant={"body_one"}>{description}</Text>
                    </div>
                  </div>

                  <Divider />
                  <div className="flex items-center">
                    {/* <FontAwesomeIcon icon={faClock} className="w-5 h-5" /> */}
                    {checkIfTBD(event?.start_epoc, event?.end_epoc) ? (
                      <div className="text-base font-medium text-zinc-600 ml-2">
                        Event date to be decided
                      </div>
                    ) : !hasEventEnded(eventData.end_epoc) ? (
                      // <div className="text-base font-medium text-zinc-600 ml-2">
                      //   {startsIn.status === "upcoming" &&
                      //     `Event is starting in ${startsIn.timeString}`}
                      //   {startsIn.status === "ongoing" && `Event has started`}
                      //   {startsIn.status === "ended" && `Event has ended`}
                      // </div>

                      <div className="flex gap-m justify-center items-center">
                        <div>
                          <IconContainer as="div" size="40">
                            <Timer size="22" className="text-color-secondary" />
                          </IconContainer>
                        </div>
                        <div className="">
                          <Text variant={"body_one_strong"}>
                            {startsIn.status === "ongoing" && `Event has started`}
                            {startsIn.status === "ended" && `Event has ended`}
                            {startsIn.status === "upcoming" && `Event is starting in`}
                          </Text>
                          <Text variant={"caption_two"}>
                            {startsIn.status === "upcoming" && startsIn.timeString}
                          </Text>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              <Divider />
              {participationDetails.status === EVENT_JOIN_STATUS.GOING &&
              !hasEventEnded(eventData.end_epoc) &&
              startsIn.status !== "ended" &&
              startsIn.status !== "ongoing" ? (
                <div
                  className="mx-auto text-center cursor-pointer"
                  onClick={() => {
                    if (typeof handleClick === "function") {
                      handleClick();
                    }
                  }}
                >
                  <Text variant={"caption_one"} className="font-normal mx-auto text-color-tertiary">
                    Cancel your registration?
                  </Text>
                </div>
              ) : null}
            </div>
          )}

        {isExploding ? (
          <div className="w-full flex justify-center">
            <ConfettiExplosion
              onComplete={() => setIsExploding(false)}
              force={0.8}
              duration={3000}
              particleCount={200}
              width={1600}
            />
          </div>
        ) : null}

        {participationDetails.role === ROLES.CREATOR ? (
          <Button
            variant={"primary"}
            className="w-full bottom-0 mt-3 mx-auto"
            // loading={isLoading || paymentLoading}
            disabled={true}
          >
            Register
          </Button>
        ) : (
          button &&
          button.text !== "Cancel" && (
            <Button
              onClick={() => {
                if (typeof handleClick === "function") {
                  handleClick();
                }
              }}
              variant={"primary"}
              className="w-full bottom-0 mt-3 mx-auto"
              loaderProps={{ size: "xs" }}
              // loading={isLoading || paymentLoading}
              disabled={
                loading || disableRegister || registrationClosed || updatingParticipationStatus
                // paymentLoading
              }
              loading={loading || updatingParticipationStatus}
            >
              {false ? button?.loadingText : button.text}
            </Button>
          )
        )}

        {participationDetails.status === EVENT_JOIN_STATUS.PAYMENT_PROCESSING ? (
          <div className=" mb-m mt-s text-center">
            <Text
              className="text-warning-400 justify-center items-center flex gap-m"
              variant={"caption_one"}
            >
              <Loader size="xs" /> Payment processing...
            </Text>
          </div>
        ) : null}

        {registrationClosed && (
          <div className="  mb-m mt-s text-center">
            <Text className="text-danger-400" variant={"caption_one"}>
              Registrations for this event are now closed.
            </Text>
          </div>
        )}

        {metaText && (
          <div className="  mb-m mt-s text-center">
            <Text className="text-danger-400" variant={"caption_one"}>
              {metaText}
            </Text>
          </div>
        )}
      </Box>
    );
  };

  const displayCancelRegistration = () => {
    const ticket =
      event?.monetary_type_details.find(
        (item: MonetaryTypeDetails) => item.id === participationDetails.ticket_id
      ) || {};
    const amount = `${getTicketPrice(ticket, eventData.event.app_fee_percentage) || ""} ${
      getCurrencyInfo(ticket.currency)?.label || ticket.currency
    }`;
    const getRefundableText = () => {
      const start_epoc = Number(event.start_epoc);
      let dayjsDate = dayjs.unix(start_epoc);

      if (ticket.refund_policy?.[0]?.valid_before) {
        dayjsDate = dayjsDate.subtract(ticket.refund_policy[0].valid_before, "seconds");
      }

      const now = dayjs();

      if (dayjsDate.unix() > now.unix()) {
        return `Upon cancellation, you will receive a refund of ${amount}.`;
      }

      return "Cancellations will no longer qualify for a refund.";
    };
    return (
      // <h1>CancelRegistration Opoup</h1>
      <Dialog open={opened} onOpenChange={closeCancelModal}>
        <DialogContent>
          <DialogTitle>Cancel your registration?</DialogTitle>
          <DialogDescription className="text-zinc-600 text-xs font-normal">
            <Text variant={"caption_one"}>
              Are you sure you want to cancel your registration for {eventData.event.name}?
            </Text>
            {ticket.type === "paid" && (
              <div className="text-xs text-zinc-500 font-normal">
                {ticket.is_refundable
                  ? getRefundableText()
                  : `This is a non-refundable event, cancellations will not qualify for a refund.`}
              </div>
            )}
          </DialogDescription>
          <div className="flex gap-m justify-between">
            <Button
              className="w-full"
              variant={"secondary"}
              onClick={() => {
                closeCancelModal();
                handleCancelStatus(EVENT_JOIN_STATUS.NOT_GOING);
              }}
            >
              Yes
            </Button>
            <Button className="w-full " onClick={() => closeCancelModal()}>
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className="!sticky top-0">
      {/* <div>{displayManageBar()}</div> */}
      {participationLoader ? (
        <Skeleton className="h-20 w-full"></Skeleton>
      ) : (
        <div>{displayRegister()}</div>
      )}
      {displayCancelRegistration()}

      {showQuestionaire ? (
        <QuestionState
          open={showQuestionaire}
          questions={questions}
          setQuestions={setQuestions}
          queResponses={queResponses}
          handleJoinEvent={questionaireResponse}
          loading={loading}
          setQueresponses={setQueresponses}
          handleCancel={() => {
            setShowQuestionaire(false);
            setQuestions([]);
            setQueresponses([]);
          }}
        />
      ) : null}
    </div>
  );
};

export default EventJoinView;
