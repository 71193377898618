import Flex from "@/components/ui/flex";
import Typography from "@/components/ui/typography";
import React from "react";
import { Text } from "./text";

interface MultiLineProps {
  icon?: any;
  titleText?: any;
  text1?: any;
  text2?: any;
  action?: any;
  [key: string]: any; // Index signature for additional props
  titleTextSize?: any;
  titleTextWeight?: any;
  className?: string;
  text1Classname?: boolean;
  verticalAlign?: "center" | "start" | "end" | "baseline" | "stretch";
}

const Multiline = ({
  icon,
  titleText,
  titleTextSize = "large",
  titleTextWeight = "extrabold",
  text1,
  text1Size,
  text1Weight,
  text2,
  text2Size,
  text2Weight,
  action,
  style,
  className,
  verticalAlign = "center",
  onClick,
  text1Classname = false,
  ...rest
}: MultiLineProps) => {
  return (
    <>
      <Flex
        direction="row"
        justify={"between"}
        align={verticalAlign}
        style={style}
        className={`px-m py-s ${className}`}
        onClick={onClick}
      >
        <Flex align={verticalAlign} className="gap-m">
          {/* <div className={styles.iconBackground}> */}
          {/* <IconContainer icon={icon} className="m-4"> */}
          {/* icon */}
          {/* </IconContainer> */}
          <div className="w-fit">{icon ? icon : null}</div>

          <Flex direction="col">
            {/* Title */}
            {titleText ? (
              <Typography size={titleTextSize} weight={"semibold"}>
                {titleText}
              </Typography>
            ) : null}
            {/* text1 */}
            <Text
              variant={text1Size ? text1Size : "caption_one"}
              // weight={text2Weight}
              className={`text-color-secondary   leading-tight !whitespace-normal ${text1Classname ? "" : "truncate line-clamp-1 break-all overflow-hidden"} `}
            >
              {text1}
            </Text>

            <Typography size={text2Size} weight={text2Weight}>
              {text2}
            </Typography>
          </Flex>
        </Flex>
        <div className="h-full">
          {/* Right Action */}
          {action}
        </div>
      </Flex>
    </>
  );
};

export default Multiline;
