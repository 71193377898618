"use client";

import { Fragment, useEffect, useMemo, useState } from "react";

// import { EditServerModal } from "@/components/modals/edit-server-modal";
import { InviteModal } from "@/modals/invite-modal";
import { CreateChannelModal } from "@/modals/create-channel-modal";
// import { EditChannelModal } from "@/components/modals/edit-channel-modal";
import { CreateChannelCategoryModal } from "@/modals/create-channel-category-modal";
import { EditChannelCategoryModal } from "@/modals/edit-channel-category-modal";
// import { CreateThreadModal } from "@/components/modals/create-thread-modal";
// import { EditThreadModal } from "@/components/modals/edit-thread-modal";
// import { EditCommentModal } from "@/components/modals/edit-comment-modal";
// import { IntroductionModal } from "@/components/modals/introduction-modal";
// import { ChannelInfoModal } from "@/components/modals/channel-info-modal";
// import { ChannelDescriptionModal } from "@/components/modals/channel-description-modal";

import { ConfirmationModal } from "@/modals/confirmation-modal";
// import { EditProfileModal } from "@/components/modals/edit-profile-modal";
import { EditMessageModal } from "@/modals/edit-message-modal";
import { UpdateFaqsModal } from "@/modals/update-faq-modal";
import { ConversationAddMemberModal } from "@/modals/conversation-add-member-modal";
import { GuestInviteModal } from "@/modals/invite-guest-modal";
import { ImageModal } from "@/modals/img-modal";
import LocationModal from "@/modals/location-modal";
import { QlLinkModal } from "@/modals/ql-link-modal";
import { EditThreadModal } from "@/modals/edit-thread-modal";
import { RejectMemberModal } from "@/modals/reject-member-modal";

import { AvatarModal } from "@/modals/avatar-modal";
import { ToggleListModal } from "@/modals/toggle-list-modal";
import { PhotoGalleryModal } from "@/modals/photo-gallery-modal";
import { ExternalLinkModal } from "@/modals/external-link-modal";
import { CTALinkModal } from "@/modals/cta-link-modal";
import { CardShowcaseModal } from "@/modals/card-showcase-modal";
import { ShareModal } from "@/modals/share-modal";
import StripeConnect from "@/modals/stripe-connect-modal";
import StripePaymentModal from "@/modals/stripe-payment-modal";
import { GenerateWithAiModal } from "@/modals/generate-with-ai-modal";
import EventPasscodeModal from "@/modals/event-passcode-modal";
import { VideoModal } from "@/modals/video-modal";
import dynamic from "next/dynamic";
import CancelGuestRegistration from "@/modals/cancel-registration-modal";
import { DeleteAccountModal } from "@/modals/delete-account-modal";
import SubmitEventToCalendar from "@/modals/submit-event-to-calendar";
import { LoaderModal } from "@/modals/loader-modal";
import { ModalType, useModal } from "@/stores/use-modal-store";
import { ImageSelectorModal } from "@/modals/image-selector-modal";
import ImageCropModalV2 from "@/modals/image-crop-modal-v2";
import { useImageCropModal } from "@/stores/use-image-crop-store";
import { useGlobalLoaderModal } from "@/stores/use-global-loader-store";
import { useLinkModal } from "@/stores/use-link-store";
import GuestQuickAdd from "@/modals/guest-quick-add";
import { useImageSelector } from "@/stores/use-image-selector.store";

const ImageCropModal = dynamic(() => import("@/modals/image-crop-modal"), { ssr: false });

const modals: Partial<Record<ModalType, React.ComponentType>> = {
  invite: InviteModal,
  imageSelector: ImageSelectorModal,
  generateWithAi: GenerateWithAiModal,
  toggleList: ToggleListModal,
  photoGallery: PhotoGalleryModal,
  externalLinks: ExternalLinkModal,
  ctaAction: CTALinkModal,
  cardShowcase: CardShowcaseModal,
  share: ShareModal,
  location: LocationModal,
  avatar: AvatarModal,
  imageCrop: ImageCropModal,
  rejectMember: RejectMemberModal,
  inviteGuest: GuestInviteModal,
  editMessage: EditMessageModal,
  confirmation: ConfirmationModal,
  imgPreview: ImageModal,

  channelAddMember: ConversationAddMemberModal,
  conversationAddMember: ConversationAddMemberModal,

  updateFaqs: UpdateFaqsModal,
  editChannelCategory: EditChannelCategoryModal,
  createChannelCategory: CreateChannelCategoryModal,
  createChannel: CreateChannelModal,
  editChannel: CreateChannelModal,
  editThread: EditThreadModal,
  stripeConnect: StripeConnect,
  stripePayment: StripePaymentModal,
  eventPasscode: EventPasscodeModal,
  cancelRegistration: CancelGuestRegistration,
  addEventToCalendar: SubmitEventToCalendar,
  videoPreview: VideoModal,
  deleteAccount: DeleteAccountModal,
  guestAdd: GuestQuickAdd
};

export const ModalProvider: React.FC = () => {
  const [isMounted, setIsMounted] = useState(false);

  const { type, isOpen } = useModal();
  const { isOpen: isLinkModalOpen } = useLinkModal();
  const { isOpen: imageCropOpen } = useImageCropModal();
  const { isOpen: isLoaderModalOpen } = useGlobalLoaderModal();
  const { isOpen: isImageSelectorOpen } = useImageSelector();

  const ModalComponent = useMemo(() => (type ? (modals[type] ?? Fragment) : Fragment), [type]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return <Fragment />;

  return (
    <Fragment>
      {isOpen ? <ModalComponent /> : null}
      {isLoaderModalOpen ? <LoaderModal /> : null}
      {isLinkModalOpen ? <QlLinkModal /> : null}
      {imageCropOpen ? <ImageCropModalV2 /> : null}
      {isImageSelectorOpen ? <ImageSelectorModal isOverLay={true} /> : null}
    </Fragment>
  );
};
