import React, { useEffect, useRef, useState } from "react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";

import { useModal } from "@/stores/use-modal-store";

import { useSession } from "next-auth/react";
import { getRoleDisplayName } from "@/lib/utils";
import { Sms } from "iconsax-react";
import { toast } from "sonner";
import { EMAIL_REGEX, getQueryKey } from "@key.ai/constants";
import eventClientApi from "@/api-client/event-client";
import { useQueryClient } from "@tanstack/react-query";
import { pluralize } from "@/lib/label";
import { useEventStore } from "@/stores/use-event-store";

const GuestQuickAdd = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { data: session }: any = useSession();

  const queryClient = useQueryClient();
  const { eventData } = useEventStore();

  const role = data?.role;

  const [emailInput, setEmailInput] = useState<string>("");
  const [subject, setSubject] = useState<string>(
    `Hi, ${"We"} invite you to join ${eventData?.name}.`
  );
  const [body, setBody] = useState<string>("");
  const [inviteLoading, setInviteLoading] = useState<boolean>(false);

  const isModalOpen = isOpen && type === "guestAdd";

  const handleEmailInputChange = (e: any) => {
    setEmailInput(e.target.value);
  };

  const isValidEmail = (email: string) => {
    return EMAIL_REGEX.test(email.trim());
  };

  useEffect(() => {
    if (eventData) {
      if (role === "guest") {
        setSubject(
          `You've been invited to attend ${eventData?.name} by ${session?.user?.firstName}`
        );
        setBody(
          `Great news! ${session?.user.firstName} has invited you to join ${eventData.name} 🥳`
        );
      } else {
        setSubject(
          `${session?.user?.firstName} has invited you to be a ${getRoleDisplayName(role as string)} at ${eventData?.name}!`
        );
        setBody(
          `Exciting news! ${session?.user.firstName} has invited you to be a ${getRoleDisplayName(role as string)} at ${eventData.name}! 🎉`
        );
      }
    }
  }, [eventData, session, role]);

  const submit = async () => {
    if (!isValidEmail(emailInput)) {
      toast.error("Email is not valid");
      return;
    }
    setInviteLoading(true);
    try {
      const data = {
        email: {
          subject: subject,
          body: btoa(encodeURIComponent(body))
        },
        invitees: [
          {
            role: role,
            emails: [emailInput]
          }
        ]
      };
      const response = await eventClientApi
        .post(`api/v2/events/${eventData?.id}/event-participations`, {
          json: { ...data }
        })
        .json<{ data: []; error_data: [] }>();

      await queryClient.refetchQueries({
        queryKey: [getQueryKey("EVENT_GUEST", { eventId: eventData?.id })]
      });

      const successInvitationMessage = response.data?.length
        ? `${response?.data?.length / 2} ${pluralize("user", response?.data?.length / 2)} invited successfully.`
        : "Invitation processed successfully. No new invitation has been sent.";

      toast.success(`${successInvitationMessage}`);
      onClose();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setInviteLoading(false);
    }
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-full-50px w-[500px]">
        <DialogDescription className="text-primary text-xl font-bold ">
          Add {getRoleDisplayName(role as string)}
        </DialogDescription>

        <Input
          variant="medium-s"
          type="text"
          className="rounded-lg mt-s w-full"
          inputClassName="w-full"
          placeholder="Enter email address"
          onChange={handleEmailInputChange}
          value={emailInput}
          leftSection={<Sms size="24" className="text-color-secondary" variant="Outline" />}
        />
        <div className="flex justify-between gap-m -z-50 mt-m items-center">
          <Button className="w-full" variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            className="w-full"
            onClick={submit}
            loaderProps={{ size: "xs" }}
            loading={inviteLoading}
            disabled={inviteLoading || !isValidEmail(emailInput)}
          >
            Send Invite
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GuestQuickAdd;
