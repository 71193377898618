import { Fragment, useMemo } from "react";
import { useUserQuery } from "@/hooks/use-user-query";
import { IconLocation } from "@tabler/icons-react";
import { User } from "@/types/entities";
import { cva } from "class-variance-authority";
import type { VariantProps } from "class-variance-authority";
import { Skeleton } from "./ui/skeleton";
import { cn } from "@/lib/utils";
import Avatar, { AvatarComponentProps } from "./ui/avatar";

const m = cva([""], {
  variants: {
    height: {
      15: "h-[15px]",
      30: "h-[30px]",
      23: "h-[23px]"
    },
    width: {
      15: "w-[15px]",
      75: "w-[75px]"
    }
  },
  defaultVariants: {
    height: 15,
    width: 75
  }
});

// export const UserEmail = ({ userId, height = 15 }: { userId: string; height?: number }) => {
//   const { data: user, status } = useUserQuery({ userId });

//   if (status === "pending")
//     return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
//   if (status === "error") return "-";
//   return <Fragment>{user?.email}</Fragment>;
// };

type UserNameProps = {
  userId: string;
  height?: number;
  ownDenote?: string;
  additionalInfo?: boolean;
} & VariantProps<typeof m>;

export const UserName = ({ userId, height, width, ownDenote }: UserNameProps) => {
  const { data: user, status } = useUserQuery({ userId });
  const { data: profile, status: s } = useUserQuery({ userId: "me" });

  if (status === "pending" || s === "pending")
    return <Skeleton className={cn(m({ height, width }))} />;
  if (status === "error") return "-";
  if (ownDenote && profile?.id === user.id) {
    return <Fragment>{ownDenote}</Fragment>;
  }
  return (
    <Fragment>
      {user.firstName} {user.lastName}
    </Fragment>
  );
};

export const UserFirstName = ({ userId, height, width, ownDenote }: UserNameProps) => {
  const { data: user, status } = useUserQuery({ userId });
  const { data: profile, status: s } = useUserQuery({ userId: "me" });

  if (status === "pending" || s === "pending")
    return <Skeleton className={cn(m({ height, width }))} />;
  if (status === "error") return "-";
  if (ownDenote && profile?.id === user.id) {
    return <Fragment>{ownDenote}</Fragment>;
  }
  return <Fragment>{user.firstName}</Fragment>;
};

// export const UserName = ({
//   userId,
//   height = 15,
//   ownDenote,
//   additionalInfo = false
// }: {
//   userId: string;
//   height?: number;
//   ownDenote?: string;
//   additionalInfo?: boolean;
// }) => {
//   const { data: user, status } = useUserQuery({ userId });
//   const { data: profile, status: s } = useUserQuery({ userId: "me" });

//   if (status === "pending" || s === "pending")
//     return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
//   if (status === "error") return "-";
//   if (ownDenote && profile?.id === user.id) {
//     return <Fragment>{ownDenote}</Fragment>;
//   }
//   return (
//     <Fragment>
//       {user.firstName} {user.lastName}{" "}
//       {additionalInfo && <span className="ml-2 text-sm text-gray-300">{user.pronunciation}</span>}
//     </Fragment>
//   );
// };

// export const UserUsername = ({ userId, height = 15 }: { userId: string; height?: number }) => {
//   const { data: user, status } = useUserQuery({ userId });

//   if (status === "pending")
//     return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
//   if (status === "error") return "-";

//   return <Fragment>{user.username}</Fragment>;
// };

// export const UserInfoByKey = ({
//   userId,
//   height = 15,
//   infoKey
// }: {
//   userId: string;
//   height?: number;
//   infoKey: keyof User;
// }) => {
//   const { data: user, status } = useUserQuery({ userId });

//   if (status === "pending")
//     return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
//   if (status === "error") return "-";
//   return user[infoKey];
// };

const userImg = cva(["rounded-full align-top"], {
  variants: {
    size: {
      16: "h-4 w-4 min-w-4",
      20: "h-5 w-5 min-w-5",
      24: "h-6 w-6 min-w-6",
      32: "h-8 w-8 min-w-8",
      40: "h-10 w-10 min-w-10",
      56: "h-[56px] w-[56px]",
      72: "h-[72px] w-[72px]",
      96: "h-24 w-24 min-w-24"
    }
  },
  defaultVariants: {
    size: 20
  }
});

export type UserImageProps = {
  userId: string;
  indicator?: any;
  avatar?: Omit<AvatarComponentProps, "variant">;
  showDefaultImg?: boolean;
} & VariantProps<typeof userImg>;

export const UserImage = ({ userId, avatar, showDefaultImg }: UserImageProps) => {
  const { data: user, status } = useUserQuery({ userId });

  // const _indicator = useMemo(() => {
  //   if (typeof indicator === "undefined") return true;
  //   return !indicator.disabled && typeof user?.isOnline !== "undefined" && user.isOnline !== true;
  // }, [indicator, user]);

  const name = useMemo(() => {
    if (user) {
      return `${String(user.firstName).charAt(0).toUpperCase()}${String(user.lastName).charAt(0).toUpperCase()}`;
    }

    return "-";
  }, [user]);

  if (status === "pending" || status === "error") {
    return <Skeleton className={cn(userImg({ size: avatar?.size }))} />;
  }

  if (showDefaultImg && !user.imageUrl) return <Avatar variant="standard" size={40}></Avatar>;

  return (
    <Avatar
      imgSrc={user?.optimized_image?.webp || user?.imageUrl || ""}
      fallbackText={name}
      variant="image"
      size={avatar?.size || 20}
      {...avatar}
    />
  );
};

// export const UserPhone = ({ userId, height = 15 }: { userId: string; height?: number }) => {
//   const { data: user, status } = useUserQuery({ userId });

//   if (status === "pending")
//     return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
//   if (status === "error") return "-";
//   return (
//     <Fragment>
//       {user?.phoneCode} {user?.phoneNumber}
//     </Fragment>
//   );
// };

// export const UserLocation = ({ userId, height = 15 }: { userId: string; height?: number }) => {
//   const { data: user, status } = useUserQuery({ userId });

//   if (status === "pending")
//     return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
//   if (status === "error") return "-";
//   return (
//     <div className="flex items-start mt-3">
//       <IconLocation style={{ width: rem(22), height: rem(22) }} />
//       {!(user.country && user.state && user.city) ? (
//         <UnstyledButton>
//           <Text>+ Add Location</Text>
//         </UnstyledButton>
//       ) : (
//         <Text className="ms-2">
//           {user.country}
//           {user.state && `, ${user.state}`}
//           {user.city && `, ${user.city}`}
//         </Text>
//       )}
//     </div>
//   );
// };

// export const UserSocial = ({ userId, height = 15 }: { userId: string; height?: number }) => {
//   const { data: member, status } = useUserQuery({ userId });

//   if (status === "pending")
//     return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
//   if (status === "error") return "-";
//   return <Fragment>{member?.socialLinks}</Fragment>;
// };

// export const UserDisplayName = ({ userId, height = 15 }: { userId: string; height?: number }) => {
//   const { data: member, status } = useUserQuery({ userId });

//   if (status === "pending")
//     return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
//   if (status === "error") return "-";
//   return <span className="text-sm text-gray-400">{member?.displayName}</span>;
// };

// export const UserPronous = ({ userId, height = 15 }: { userId: string; height?: number }) => {
//   const { data: member, status } = useUserQuery({ userId });

//   if (status === "pending")
//     return <Skeleton component="span" className="block w-[75px]" height={height} radius="xl" />;
//   if (status === "error") return "-";
//   return <span className="text-sm text-gray-400">{member?.pronouns}</span>;
// };
