"use client";

import { useModal } from "@/stores/use-modal-store";
import dynamic from "next/dynamic";
import { Fragment } from "react";
import EditorLoader from "@/components/editor-loader";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import Box from "@/components/ui/box";
import { useCrypto } from "@/providers/crypto-provider";
import { useConversationKeys } from "@/hooks/use-conversations-query";

const MessageEditor = dynamic(() => import("@/components/editor/message-editor"), {
  ssr: false,
  loading: EditorLoader
});

export const EditMessageModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { message, msgType } = data;
  const { lastSyncVersion, privateKeys } = useCrypto();

  const { data: conversationKeys } = useConversationKeys(message!.conversationId);

  const isModalOpen = isOpen && type === "editMessage";

  const handleClose = () => onClose();

  if (!message) return <Fragment />;

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[750px] p-4 overflow-visible">
        <DialogHeader>
          <DialogTitle>Edit Message</DialogTitle>
        </DialogHeader>
        <Box className="pt-0">
          <MessageEditor
            msg={message}
            query={{
              conversationId: message!.conversationId,
              serverId: message!.serverId
            }}
            type={msgType}
            myEncryptionKey={conversationKeys?.hex}
            privateKeys={privateKeys}
            lastSyncVersion={lastSyncVersion}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
