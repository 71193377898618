"use client";

import clientApi from "@/api-client/client";
import { ChannelMode, ChannelType } from "@key.ai/enum";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useParams, useRouter } from "next/navigation";
import { useModal } from "@/stores/use-modal-store";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@key.ai/constants";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Text } from "@/components/ui/text";
import { toast } from "sonner";
import { useSession } from "next-auth/react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import EmojiPicker from "@/components/custom/emoji-picker";
import { Instance } from "tippy.js";
import IconContainer from "@/components/ui/icon-container";
import { Hash } from "lucide-react";
import { ApiResponse } from "@/types/api";
import { Channel } from "@/types/entities";

const Schema = yup.object({
  name: yup
    .string()
    .required("This field is required.")
    .max(80, "Channel name cannot exceed 80 characters.")
    .test(
      "is-not-only-spaces",
      "Channel name cannot be only spaces.",
      (value) => value.trim().length > 0
    ),
  description: yup.string(),
  type: yup.string().required("This field is required.").oneOf(Object.values(ChannelType)),
  mode: yup.string().required("This field is required.").oneOf(Object.values(ChannelMode))
});

export const CreateChannelModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { data: session } = useSession();
  const params = useParams<{ domain: string; channelId: string }>();
  const [instance, setInstance] = useState<Instance | null>(null);

  const isModalOpen = isOpen && (type === "createChannel" || type === "editChannel");
  const { channelType, categoryId, channel } = data;
  const router = useRouter();

  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      type: ChannelType.TEXT,
      mode: ChannelMode.PUBLIC,
      avatar: null
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        if (channel && type === "editChannel") {
          const data = await clientApi
            .patch(`api/v1/${channel?.serverId}/channels/${channel?.id}`, {
              json: values
            })
            .json<ApiResponse<Channel>>();
          router.replace(`/channels/${data.data.slug}`);
        } else {
          await clientApi.post(`api/v1/${params.domain}/channels`, {
            json: { ...values, categoryId }
          });
        }
        await queryClient.refetchQueries({
          queryKey: [getQueryKey("CHANNELS", { serverId: params.domain })]
        });
        await queryClient.refetchQueries({
          queryKey: [getQueryKey("UNREADS", { userId: session!.user.id })]
        });
        if (params.channelId) {
          await queryClient.refetchQueries({
            queryKey: [
              getQueryKey("CHANNEL", { serverId: params.domain, channelId: params.channelId })
            ]
          });
        }
        resetForm();
        onClose();
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (channelType) {
      formik.setFieldValue("type", channelType);
    } else {
      formik.setFieldValue("type", ChannelType.TEXT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelType]);

  useEffect(() => {
    if (channel && isOpen && type === "editChannel") {
      formik.setFieldValue("name", channel.name);
      formik.setFieldValue("mode", channel.mode);
      formik.setFieldValue("description", channel.description || "");
      formik.setFieldValue("avatar", channel.avatar || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, isOpen, type]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const ChannelTitle = () => {
    return (
      <Text variant="body_two_strong">
        {type === "editChannel" ? "Edit" : "Create"}{" "}
        {formik.values.name
          ? `${formik.values.avatar ? formik.values.avatar + " " : "#"}${formik.values.name}`
          : "Channel"}
      </Text>
    );
  };

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[550px] p-4" isShowCloseBtn={false}>
        <div className="px-4 pb-2">
          <ChannelTitle />
        </div>

        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div className="mb-5 w-full">
            <div ref={ref}></div>
            <div className="w-full flex gap-2">
              <Input
                placeholder="Channel name"
                error={(formik.touched.name && formik.errors.name) as boolean}
                errorMessage={formik.errors.name}
                errorOutline={(formik.touched.name && formik.errors.name) as boolean}
                variant="medium"
                name="name"
                type="text"
                value={formik.values.name}
                onChange={(e) => {
                  const lowercaseValue = e.target.value.toLowerCase();
                  formik.setFieldValue("name", lowercaseValue);
                }}
                fullWidth={true}
                leftSectionBgClassName="py-0 pr-0 pl-m"
                leftSection={
                  <EmojiPicker
                    onChange={(emoji) => {
                      formik.setFieldValue("avatar", emoji);
                      if (instance) {
                        instance.hide();
                      }
                    }}
                    offset={[0, 0]}
                    maxWidth="none"
                    onMount={setInstance}
                    onClickOutside={(instance) => {
                      if (instance) {
                        instance.hide();
                      }
                    }}
                    hideOnClick="toggle"
                    trigger="click"
                    placement="auto"
                    appendTo={() => ref.current ?? document.body}
                  >
                    <IconContainer
                      as="span"
                      size="medium"
                      variant="group"
                      className="rounded-sm shadow-none bg-white cursor-pointer"
                      icon={
                        formik.values.avatar ? (
                          formik.values.avatar
                        ) : (
                          <Hash size={15} className="text-primary" />
                        )
                      }
                    />
                  </EmojiPicker>
                }
              />
            </div>
            <div className="text-xs mt-4 ml-4">
              <Text variant={"caption_one"} color={"secondary"}>
                Channels are where conversations happen around a topic. Use a name that is easy to
                find and understand.
              </Text>
            </div>
          </div>

          <div className="mb-5 w-full">
            <div className="px-4 pb-2 pt-10">
              <Text>Visibility</Text>
            </div>
            <div className="px-4 pt-4 pb-2">
              <RadioGroup
                defaultValue={ChannelMode.PUBLIC}
                value={formik.values.mode}
                onValueChange={(value) => {
                  formik.setFieldValue("mode", value);
                }}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value={ChannelMode.PUBLIC} id="mode" />
                  <Text>Public – Anyone in Community</Text>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value={ChannelMode.PRIVATE} id="mode" />
                  <Text>Private – Only specific people</Text>
                </div>
              </RadioGroup>
            </div>
            {formik.errors.mode && formik.touched.mode ? (
              <Text as="span" className={"text-red-500 text-xs pl-s-nudge"}>
                {formik.errors.mode}
              </Text>
            ) : null}
          </div>
          <div className="flex w-full justify-center gap-6 pt-8">
            <Button
              disabled={formik.isSubmitting}
              type="button"
              loaderProps={{ size: "xs" }}
              onClick={() => {
                handleClose();
              }}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              type="submit"
              loaderProps={{ size: "xs" }}
            >
              {type === "editChannel" ? "Save" : "Create"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
