import clientApi from "@/api-client/client";
import { decryptUsingAES, encryptUsingAES } from "@/lib/crypto";
import { cleanObject } from "@/lib/helper";
import { ApiResponse } from "@/types/api";
import moment from "moment";
export const useCreateKey = () => {
  const getKeys = async () => {
    const { data } = await clientApi
      .get(`api/v1/common/get-key-pairs`, {
        searchParams: cleanObject({})
      })
      .json<
        ApiResponse<{
          publicKey: string;
          privateKey: string;
          lastSync: number;
          lastSyncVersion: number;
          privateKeys: string[];
          publicKeys: string[];
        }>
      >();
    return data;
  };
  const generateAndStoreKeys = async (syncVersion: number, syncTime: number) => {
    const response = await fetch("/api/crypto");
    const keys = await response.json();
    const aesKey = Buffer.from(process.env.NEXT_PUBLIC_AES_KEY ?? "", "hex");
    const encryptedPrivateKey = encryptUsingAES(keys?.privateKey, aesKey, 1);
    const { data } = await clientApi
      .post(`api/v1/common/generate-key-pairs`, {
        json: {
          publicKey: keys?.publicKey,
          privateKey: encryptedPrivateKey,
          syncVersion: syncVersion,
          syncTime: syncTime
        }
      })
      .json<
        ApiResponse<{
          publicKey: string;
          privateKey: string;
          lastSync: number;
          lastSyncVersion: number;
          privateKeys: string[];
          publicKeys: string[];
        }>
      >();
    return data;
  };
  const doKeysSync = async () => {
    const keys = await getKeys();
    let decryptedPrivateKey: string = "";
    let publicKey: string = "";
    let publicKeys: string[] = [];
    const privateKeys: string[] = [];
    let lastSyncVersion = 0;
    const aesKey = Buffer.from(process.env.NEXT_PUBLIC_AES_KEY ?? "", "hex");
    const current = moment.utc().valueOf();
    const commonDecryptionTask = (keys: any) => {
      // decrypt the private key using aes key
      decryptedPrivateKey = decryptUsingAES(keys.privateKey, [aesKey]);
      publicKey = keys.publicKey;

      publicKeys = keys?.publicKeys ?? [];

      /* Decrypt all the version of private keys */
      for (const key of keys.privateKeys) {
        const decryptedPrivateKey = decryptUsingAES(key, [aesKey]);
        privateKeys.push(decryptedPrivateKey);
      }
    };
    if (keys?.privateKey && keys?.publicKey) {
      if (
        keys?.lastSync &&
        moment(current).diff(
          keys?.lastSync,
          process.env.NEXT_PUBLIC_E2E_ROTATION_COUNT as moment.unitOfTime.Diff
        ) > Number(process.env.NEXT_PUBLIC_E2E_ROTATION_INTERVAL)
      ) {
        // if keys is older than 3 months, generate new and give to server for storing.
        lastSyncVersion = keys?.lastSyncVersion ?? 0;
        const keysGenerated = await generateAndStoreKeys(lastSyncVersion + 1, current);

        commonDecryptionTask(keysGenerated);
      } else {
        // continue with same key. // we have keys do nothing.
        commonDecryptionTask(keys);
      }
    } else {
      const keys = await generateAndStoreKeys(lastSyncVersion + 1, current);
      commonDecryptionTask(keys);
    }
    //increase version
    lastSyncVersion = lastSyncVersion + 1;

    return {
      id: lastSyncVersion,
      privateKey: decryptedPrivateKey,
      publicKey,
      publicKeys,
      privateKeys,
      lastSyncVersion: lastSyncVersion
    };
  };
  return {
    doKeysSync
  };
};
