"use client";

import * as yup from "yup";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { useParams } from "next/navigation";
import { useModal } from "@/stores/use-modal-store";
import clientApi from "@/api-client/client";
import { FieldArray, FieldArrayRenderProps, FormikProvider, useFormik } from "formik";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ApiResponse } from "@/types/api";
import { Fragment, useEffect, useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import IconContainer from "@/components/ui/icon-container";
import { get } from "lodash";
import { toast } from "sonner";
import { IconX } from "@tabler/icons-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import { ArrowDown2 } from "iconsax-react";
import { cn } from "@/lib/utils";
import Divider from "@/components/ui/divider";
import { HomeSections } from "@key.ai/enum";
import { v4 as uuidv4 } from "uuid";
import { ScrollArea } from "@/components/ui/scroll-area";
import { X } from "lucide-react";

const Schema = yup.object({
  title: yup.string().required("This field is required.").max(100, "Max 100 characters allowed."),
  items: yup.array().of(
    yup.object().shape({
      title: yup.string().required("This field is required."),
      description: yup.string().required("This field is required.")
    })
  )
});

interface FaqFormInterface {
  items: {
    title: string;
    description: string;
  }[];
  title?: string;
  clientId?: string;
}

const defaultFaq: any = {
  title: "",
  description: ""
};

export const ToggleListModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const params = useParams<{ domain: string }>();
  const { faqs, callback } = data;

  const [current, setCurrent] = useState<string[]>([]);

  const isModalOpen = isOpen && type === "toggleList";

  const formik = useFormik<FaqFormInterface>({
    initialValues: {
      items: [],
      title: "",
      clientId: ""
    },
    validationSchema: Schema,
    onSubmit: async ({ title, items, clientId }, { setSubmitting, resetForm }) => {
      try {
        const payload = {
          title: title,
          clientId,
          json: items,
          order: Number(faqs?.order),
          section: HomeSections.Accordion,
          attachments: []
        };

        await clientApi
          .post(`api/v1/${params.domain}/server-sections`, { json: payload })
          .json<ApiResponse>();

        callback?.(formik.values);
        resetForm();
        onClose();
        toast.success("Saved!");
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const addNew = (helpers: FieldArrayRenderProps) => {
    const set = new Set(current);
    set.add(String(formik.values.items.length));
    setCurrent(Array.from(set));

    helpers.push(defaultFaq);
  };

  useEffect(() => {
    if (faqs && isOpen) {
      if (faqs.section.items.length === 0) {
        formik.setFieldValue("items", [defaultFaq]);
        setCurrent(["0"]);
      } else {
        formik.setFieldValue("items", faqs.section.items);
      }
      formik.setFieldValue("title", faqs.section.title || "");
      formik.setFieldValue("clientId", faqs.clientId || uuidv4());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqs, isOpen]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent
        className="max-w-full-50px w-[550px] overflow-hidden p-0 bg-none border-none backdrop-blur-none shadow-none"
        style={{
          height: "calc(100dvh - 90px)",
          background: "transparent"
        }}
        isShowCloseBtn={false}
      >
        <div className="w-full max-h-full flex flex-col overflow-hidden">
          <div className="rounded-medium border-2 border-border bg-modal overflow-hidden py-4 shadow-lg backdrop-blur-20 flex flex-col my-auto">
            <div className="flex-shrink-0 px-4">
              <DialogHeader className="flex-shrink-0">
                <div className="flex gap-2">
                  <DialogTitle>Toggle List</DialogTitle>
                  <DialogClose className="ms-auto">
                    <X className="h-4 w-4" />
                  </DialogClose>
                </div>
              </DialogHeader>
            </div>
            <FormikProvider value={formik}>
              <FieldArray
                name="items"
                render={(helpers) => {
                  return (
                    <Fragment>
                      <div className="flex-shrink-1 overflow-hidden">
                        <ScrollArea className="h-full px-4">
                          <div className="w-full flex-shrink-1 flex flex-col ">
                            <Input
                              placeholder="Section Title"
                              variant="large"
                              unstyled
                              className="font-semibold text-2xl"
                              onChange={formik.handleChange}
                              value={formik.values.title}
                              name="title"
                              error={(formik.touched.title && formik.errors.title) as boolean}
                              errorMessage={formik.errors.title}
                              errorOutline={
                                (formik.touched.title && formik.errors.title) as boolean
                              }
                            />

                            <Accordion
                              type="multiple"
                              value={current}
                              onValueChange={setCurrent}
                              className="w-full pb-m"
                            >
                              {formik.values.items.map((item, index) => {
                                const error = get(formik.errors, `items[${index}].title`);
                                const touched = get(formik.touched, `items[${index}].title`);

                                const _error = get(formik.errors, `items[${index}].description`);
                                const _touched = get(formik.touched, `items[${index}].description`);

                                return (
                                  <AccordionItem
                                    value={String(index)}
                                    key={index}
                                    className={cn(
                                      (error && touched) || (_error && _touched)
                                        ? "border-1 border-red-500 mt-l"
                                        : " mt-l"
                                    )}
                                  >
                                    <div className="flex items-start">
                                      <div className="me-3 w-full flex-shrink-1">
                                        <div className="flex items-start">
                                          <Input
                                            placeholder="Title"
                                            fullWidth
                                            onChange={formik.handleChange}
                                            value={item.title}
                                            name={`items[${index}].title`}
                                            error={(touched && error) as boolean}
                                            errorMessage={error}
                                            errorOutline={(touched && error) as boolean}
                                            unstyled
                                            inputClassName="px-m !py-0"
                                            className="font-semibold"
                                          />
                                          <div className="mt-[10px]">
                                            <AccordionTrigger
                                              arrowIcon={<ArrowDown2 size={16} />}
                                            />
                                          </div>
                                        </div>
                                        <Divider />

                                        <AccordionContent>
                                          <Textarea
                                            placeholder="Add Details"
                                            className="w-full mt-3"
                                            textareaClassName="bg-transparent !p-m-nudge "
                                            onChange={formik.handleChange}
                                            value={item.description}
                                            name={`items[${index}].description`}
                                            error={(_touched && _error) as boolean}
                                            errorMessage={_error}
                                            unstyled
                                          />
                                        </AccordionContent>
                                      </div>
                                      <IconContainer
                                        size={"medium"}
                                        onClick={() => helpers.remove(index)}
                                        className="rounded-lg flex-shrink-0 "
                                        type="button"
                                      >
                                        <IconX size={16} />
                                      </IconContainer>
                                    </div>
                                  </AccordionItem>
                                );
                              })}
                            </Accordion>
                          </div>
                        </ScrollArea>
                      </div>
                      <div className="flex-shrink-0 px-4">
                        <div className="flex items-center justify-center gap-2xl flex-shrink-0">
                          <Button
                            variant="secondary"
                            type="button"
                            className="w-[180px]"
                            onClick={() => addNew(helpers)}
                          >
                            Add More
                          </Button>

                          <Button
                            className="w-[180px]"
                            disabled={formik.isSubmitting}
                            loading={formik.isSubmitting}
                            onClick={() => formik.handleSubmit()}
                            type="button"
                            loaderProps={{ size: "xs" }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Fragment>
                  );
                }}
              />
            </FormikProvider>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
