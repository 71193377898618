import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import clientApi from "@/api-client/client";
import { ApiPaginationWithTotal, ApiResponse } from "@/types/api";
import { Conversation } from "@/types/entities";
import { getQueryKey } from "@key.ai/constants";
import { cleanObject } from "@/lib/helper";
import { compact } from "lodash";
import { FavouriteType } from "@key.ai/enum";

export const useConversationsQuery = () => {
  const queryKey = getQueryKey("PERSONAL_CONVERSATIONS");

  const fetchConversations = async ({ pageParam = undefined }: { pageParam: unknown }) => {
    const { data: conversations } = await clientApi
      .get(`api/v1/conversations/personal`, {
        searchParams: cleanObject({ cursor: pageParam as string })
      })
      .json<ApiResponse<ApiPaginationWithTotal<Conversation[]>>>();

    return conversations;
  };

  return useInfiniteQuery<ApiPaginationWithTotal<Conversation[]>>({
    queryKey: compact([queryKey]),
    queryFn: fetchConversations,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    initialPageParam: undefined,
    getPreviousPageParam: (firstPage) => firstPage?.previousCursor,
    staleTime: Infinity,
    gcTime: 3 * 1000 * 60
  });
};

export const useEventConversationsQuery = ({
  eventId,
  serverId
}: {
  eventId: string;
  serverId: string;
}) => {
  const queryKey = getQueryKey("EVENT_CONVERSATIONS");

  const fetchConversations = async ({ pageParam = undefined }: { pageParam: unknown }) => {
    const { data: conversations } = await clientApi
      .get(`api/v1/conversations/event-group/${eventId}`, {
        searchParams: cleanObject({ cursor: pageParam as string, serverId: serverId as string })
      })
      .json<ApiResponse<ApiPaginationWithTotal<Conversation[]>>>();

    return conversations;
  };

  return useInfiniteQuery<ApiPaginationWithTotal<Conversation[]>>({
    queryKey: compact([queryKey]),
    queryFn: fetchConversations,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    initialPageParam: undefined,
    getPreviousPageParam: (firstPage) => firstPage?.previousCursor,
    staleTime: Infinity,
    gcTime: 3 * 1000 * 60
  });
};

export const useConversationsBotsQuery = ({ serverId }: { serverId: string }) => {
  const queryKey = getQueryKey("BOT_CONVERSATIONS", {
    serverId: serverId
  });

  const fetchConversations = async ({ pageParam = undefined }: { pageParam: unknown }) => {
    const { data: conversations } = await clientApi
      .get(`api/v1/conversations/my-bot`, {
        searchParams: cleanObject({
          cursor: pageParam as string,
          serverId: serverId
        })
      })
      .json<ApiResponse<ApiPaginationWithTotal<Conversation[]>>>();

    return conversations;
  };

  return useInfiniteQuery<ApiPaginationWithTotal<Conversation[]>>({
    queryKey: compact([queryKey, serverId]),
    queryFn: fetchConversations,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    initialPageParam: undefined,
    getPreviousPageParam: (firstPage) => firstPage?.previousCursor,
    staleTime: Infinity,
    gcTime: 3 * 1000 * 60
  });
};

export const useEventsChatQuery = () => {
  const queryKey = getQueryKey("EVENT_CHATS");

  const fetchConversations = async ({ pageParam = undefined }: { pageParam: unknown }) => {
    const { data: conversations } = await clientApi
      .get(`api/v1/conversations/event`, {
        searchParams: cleanObject({ cursor: pageParam as string })
      })
      .json<ApiResponse<ApiPaginationWithTotal<Conversation[]>>>();

    return conversations;
  };

  return useInfiniteQuery<ApiPaginationWithTotal<Conversation[]>>({
    queryKey: compact([queryKey]),
    queryFn: fetchConversations,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    initialPageParam: undefined,
    getPreviousPageParam: (firstPage) => firstPage?.previousCursor,
    staleTime: Infinity,
    gcTime: 3 * 1000 * 60
  });
};

export const useFavouritesConversationsQuery = (isServerBar = false) => {
  const queryKey = getQueryKey("FAVOURITES_CONVERSATIONS");

  const fetchConversations = async ({ pageParam = undefined }: { pageParam: unknown }) => {
    const { data: conversations } = await clientApi
      .get(`api/v1/conversations/favourites`, {
        searchParams: cleanObject({
          cursor: pageParam as string,
          types: isServerBar
            ? `${FavouriteType.ONE_TO_MANY},${FavouriteType.ONE_TO_ONE},${FavouriteType.EVENT},${FavouriteType.CHANNEL}`
            : `${FavouriteType.ONE_TO_MANY},${FavouriteType.ONE_TO_ONE},${FavouriteType.EVENT}`
        })
      })
      .json<ApiResponse<ApiPaginationWithTotal<Conversation[]>>>();

    return conversations;
  };

  return useInfiniteQuery<ApiPaginationWithTotal<Conversation[]>>({
    queryKey: compact([queryKey]),
    queryFn: fetchConversations,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    initialPageParam: undefined,
    getPreviousPageParam: (firstPage) => firstPage?.previousCursor,
    staleTime: Infinity,
    gcTime: 3 * 1000 * 60
  });
};

export const useConversationKeys = (conversationId: string) => {
  const queryKey = getQueryKey("CONVERSATION_KEYS", { conversationId: conversationId });

  const fetchKeyPair = async () => {
    const { data } = await clientApi
      .post(`api/v1/common/generate-conversation-key`, {
        body: JSON.stringify({
          conversationId: conversationId
        })
      })
      .json<ApiResponse<{ userId: string; hex: string[] }>>();

    return data;
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: fetchKeyPair,
    refetchInterval: false,
    staleTime: Infinity
  });
};
