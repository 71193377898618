import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import clientApi from "@/api-client/client";
import { ApiResponse, ApiResponsePagination } from "@/types/api";
import { Conversation, Server } from "@/types/entities";
import { getQueryKey } from "@key.ai/constants";
import { ICommunityUser } from "@/types/cusom-types";
import { cleanObject } from "@/lib/helper";

interface UserInterface extends ICommunityUser {
  conversation: Conversation | null;
}

type UserQueryProps = {
  userId: string;
  initialData?: UserInterface;
};

export const useUserQuery = ({ userId, initialData }: UserQueryProps) => {
  const queryKey = getQueryKey("USER_INFO", { userId });

  const fetchUser = async (userId: string) => {
    const { data: user } = await clientApi
      .get(`api/v1/profile/${userId}`)
      .json<ApiResponse<{ user: ICommunityUser; conversation: Conversation }>>();

    return { ...user.user, conversation: user.conversation };
  };

  return useQuery<UserInterface>({
    queryKey: [queryKey],
    queryFn: () => fetchUser(userId),
    refetchInterval: false,
    staleTime: Infinity,
    initialData,
    enabled: Boolean(userId)
  });
};

export const useCommunitiesQuery = ({ userId }: UserQueryProps) => {
  const queryKey = getQueryKey("COMMUNITIES");

  const fetchCommunities = async ({ pageParam = undefined }: { pageParam: unknown }) => {
    const { data } = await clientApi
      .get(`api/v1/profile/list-communities`, {
        searchParams: cleanObject({ userId: userId, cursor: pageParam as string })
      })
      .json<ApiResponse<ApiResponsePagination<Server[]>>>();

    return data;
  };

  return useInfiniteQuery<ApiResponsePagination<Server[]>>({
    queryKey: [queryKey, userId],
    queryFn: fetchCommunities,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
    initialPageParam: undefined
  });
};

export const useUserKeys = () => {
  const queryKey = getQueryKey("USER_KEYS");

  const fetchKeyPair = async () => {
    const { data } = await clientApi
      .get(`api/v1/common/generate-key-pairs`)
      .json<ApiResponse<{ privateKey: string; publicKey: string }>>();

    return data;
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: fetchKeyPair,
    refetchInterval: false,
    staleTime: Infinity
  });
};
