"use client";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose
} from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import clientApi from "@/api-client/client";
import { useState } from "react";
import { ApiResponse } from "@/types/api";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";

export const ConfirmationModal = () => {
  const { isOpen, type, data, onClose } = useModal();
  const {
    confirmationTitle,
    apiUrl,
    json,
    searchParams,
    method,
    callback,
    before,
    after,
    onError
  } = data;
  const [loading, setLoading] = useState(false);

  const isModalOpen = isOpen && type === "confirmation";

  const handleClose = () => onClose();

  const confirmAction = async () => {
    try {
      setLoading(true);
      await before?.();
      const data = await clientApi(apiUrl!, {
        method: method,
        json: json || {},
        searchParams: searchParams || undefined
      }).json<ApiResponse>();
      await after?.();
      callback?.(data);
      toast.success(data.message);
      handleClose();
    } catch (err) {
      handleClose();
      if (typeof onError === "function") {
        onError?.(err);
      } else {
        toast.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent
        className="max-w-full w-[90%] sm:w-[550px] p-4 !pt-[30px] overflow-visible"
        aria-labelledby="dialog-title"
      >
        <DialogHeader>
          <DialogTitle>{confirmationTitle}</DialogTitle>
        </DialogHeader>

        <div className="flex gap-2">
          <Button
            disabled={loading}
            loading={loading}
            loaderProps={{ size: "xs" }}
            onClick={confirmAction}
          >
            Confirm
          </Button>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
