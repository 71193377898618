import * as crypto from "crypto";

export const encryptUsingAES = (message: string, key: Buffer | string, version: number) => {
  const algorithm = "aes-256-cbc";
  const iv = crypto.randomBytes(16); // Generate a random IV for each message
  // Encrypt the message using the group AES key
  const cipher = crypto.createCipheriv(algorithm, key, iv);
  let encryptedMessage = cipher.update(message, "utf8", "hex");
  encryptedMessage += cipher.final("hex");
  return [encryptedMessage, iv.toString("hex"), version].join("|");
};

export const decryptUsingAES = (encryptedMessage: string, key: Buffer[] | string[]) => {
  const [encrypted, iv, version] = encryptedMessage.split("|");
  const algorithm = "aes-256-cbc";
  const actualKey = key[Number(version ?? 1) - 1] ?? key.at(-1);
  // Decrypt the message using the group AES key
  const decipher = crypto.createDecipheriv(algorithm, actualKey, Buffer.from(iv, "hex"));

  let decryptedMessage = decipher.update(encrypted, "hex", "utf8");
  decryptedMessage += decipher.final("utf8");
  return decryptedMessage;
};

//aes
// aes
export const publicEncrypt = (key: string, conversationKey: Buffer) => {
  return crypto.publicEncrypt(
    {
      key: key,
      padding: crypto.constants.RSA_PKCS1_OAEP_PADDING
    },
    conversationKey
  );
};

export const privateDecrypt = (key: string, encryptedConversationKey: Buffer) => {
  return crypto.privateDecrypt(
    {
      key: key,
      padding: crypto.constants.RSA_PKCS1_OAEP_PADDING
    },
    encryptedConversationKey
  );
};

export const scryptSync = (key: string) => {
  return crypto.scryptSync(key, "salt", 40);
};

// Tokenize the message into words
export function tokenizeMessage(message: string) {
  return message.toLowerCase().split(/\s+/); // Split by spaces
}

// Hash each token securely
export function hashToken(token: string) {
  return crypto.createHash("sha256").update(token).digest("hex");
}
