"use client";

import * as yup from "yup";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { useFormik } from "formik";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import Box from "@/components/ui/box";
import { Dropzone } from "@/components/ui/dropzone";
import { MIME_TYPES } from "@/types/mime-types";
import { DocumentUpload, Trash } from "iconsax-react";
import { Text } from "@/components/ui/text";
import Image from "next/image";
import { toast } from "sonner";
import clientApi from "@/api-client/client";
import { ApiResponse } from "@/types/api";
import { useParams } from "next/navigation";
import { HomeSections } from "@key.ai/enum";
import { EndPoints } from "@/types/uploadthing";
import { uploadFile } from "@/lib/uploadthing";
import { isValidUrl } from "@/lib/url";
import IconContainer from "@/components/ui/icon-container";
import { CardShowcaseCard } from "@/types/homepage";
import { maxFiveMb } from "@/config/max-size";

const Schema = yup.object({
  title: yup
    .string()
    .required("This field is required.")
    .trim()
    .max(50, "Max 50 characters allowed."),
  subtitle: yup
    .string()
    .required("This field is required.")
    .trim()
    .max(50, "Max 50 characters allowed."),
  link: yup
    .string()
    .test("is-url-valid", "Please enter valid URL.", (value) => (value ? isValidUrl(value) : true))
});

export const CardShowcaseModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { cardShowcase, callback } = data;
  const params = useParams<{ domain: string }>();
  const isModalOpen = isOpen && type === "cardShowcase";

  const formik = useFormik({
    initialValues: {
      title: "",
      subtitle: "",
      link: "",
      image: "",
      imageBlob: null,
      imageLiveUrl: null
    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        if (!cardShowcase) return;
        setSubmitting(true);
        values.link = values.link.startsWith("http")
          ? values.link
          : values.link
            ? `https://${values.link}`
            : "";

        if (values.imageBlob && !values.imageLiveUrl) {
          const { url } = await uploadFile(values.imageBlob, EndPoints.Section);
          values["image"] = url;
        }

        let cards: CardShowcaseCard[] = [];
        if (typeof cardShowcase.index === "undefined") {
          cards = [...cardShowcase.section.cards, values];
        } else {
          if (cardShowcase.section.cards[cardShowcase.index]) {
            cardShowcase.section.cards[cardShowcase.index] = values;
            cards = [...cardShowcase.section.cards];
          } else {
            cards = [...cardShowcase.section.cards, values];
          }
        }

        await clientApi
          .post(`api/v1/${params.domain}/server-sections`, {
            json: {
              title: cardShowcase.section.title,
              clientId: cardShowcase.clientId,
              json: { cards },
              order: Number(cardShowcase.order),
              section: HomeSections.CardShowcase,
              attachments: []
            }
          })
          .json<ApiResponse>();
        callback?.(cards);
        resetForm();
        onClose();
        toast.success("Saved!");
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleImg = async (f: File | null) => {
    if (!f) return;
    await formik.setFieldValue("imageBlob", f);
    await formik.setFieldValue("image", URL.createObjectURL(f));
    await formik.setFieldValue("imageLiveUrl", "");
  };

  const removeImg = async (f: File | null) => {
    await formik.setFieldValue("imageBlob", null);
    await formik.setFieldValue("image", "");
    await formik.setFieldValue("imageLiveUrl", "");
  };

  useEffect(() => {
    try {
      if (cardShowcase && isOpen && typeof cardShowcase.index !== "undefined") {
        const card = cardShowcase.section.cards[cardShowcase.index];
        formik.setFieldValue("title", card.title || "");
        formik.setFieldValue("subtitle", card.subtitle || "");
        formik.setFieldValue("link", card.link || "");
        formik.setFieldValue("image", card.image || "");
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardShowcase, isOpen]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[550px] p-4 max-h-[90%] !gap-2xl">
        <DialogHeader>
          <DialogTitle>Card Showcase</DialogTitle>
        </DialogHeader>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2xl">
          <div className="grid grid-cols-3 gap-4 overflow-hidden">
            <div className="col-span-3">
              <Box withBorder className="gap-l flex flex-col">
                {formik.values.image ? (
                  <div className="w-[140px] h-[140px] flex items-center justify-center mx-auto">
                    <div className="relative w-full h-full">
                      <IconContainer
                        className="absolute -top-2 -right-2 z-20"
                        icon={<Trash size={10} className="text-white" />}
                        size={24}
                        variant="danger"
                        onClick={removeImg}
                        type="button"
                      />

                      <Image
                        src={formik.values.image}
                        alt=""
                        fill
                        className="w-full h-full rounded-full object-cover"
                      />
                    </div>
                  </div>
                ) : (
                  <Dropzone
                    accept={{
                      [MIME_TYPES.png]: [],
                      [MIME_TYPES.gif]: [],
                      [MIME_TYPES.jpeg]: [],
                      [MIME_TYPES.svg]: [],
                      [MIME_TYPES.webp]: [],
                      [MIME_TYPES.avif]: [],
                      [MIME_TYPES.heic]: []
                    }}
                    onDrop={(files) => handleImg(files[0])}
                    multiple={false}
                    maxSize={maxFiveMb}
                    handleImg={(files) => handleImg(files)}
                    cropRatio="PROFILE"
                  >
                    <Box className="p-4xl h-[240px] flex items-center justify-center border-dashed border-1 border-color-primary border-opacity-50">
                      <Button
                        type="button"
                        variant="secondary"
                        className="mx-auto w-auto shadow-none"
                        layerClassName="py-[4px] flex items-center justify-center"
                      >
                        <DocumentUpload size={18} />
                        <Text className="ms-2">Drag or Drop Image</Text>
                      </Button>
                    </Box>
                  </Dropzone>
                )}

                <div>
                  <Input
                    placeholder="Title"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    name="title"
                    error={(formik.touched.title && formik.errors.title) as boolean}
                    errorMessage={formik.errors.title}
                    errorOutline={(formik.touched.title && formik.errors.title) as boolean}
                    unstyled
                    className="font-semibold text-2xl"
                  />

                  <Input
                    placeholder="Subtitle"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.subtitle}
                    name="subtitle"
                    error={(formik.touched.subtitle && formik.errors.subtitle) as boolean}
                    errorMessage={formik.errors.subtitle}
                    errorOutline={(formik.touched.subtitle && formik.errors.subtitle) as boolean}
                    unstyled
                  />

                  <Input
                    placeholder="Paste URL (Optional)"
                    fullWidth
                    label="Paste URL (Optional)"
                    onChange={formik.handleChange}
                    value={formik.values.link}
                    labelClassName="mx-m mt-l"
                    name="link"
                    error={(formik.touched.link && formik.errors.link) as boolean}
                    errorMessage={formik.errors.link}
                    errorOutline={(formik.touched.link && formik.errors.link) as boolean}
                  />
                </div>
              </Box>
            </div>
            {/* <div className="overflow-hidden">
              <Box withBorder className="w-full flex justify-center flex-col gap-l items-center">
                <div className="w-[140px] h-[140px] flex items-center justify-center">
                  {formik.values.image ? (
                    <div className="relative w-full h-full">
                      <Image
                        src={formik.values.image}
                        alt=""
                        fill
                        className="w-full h-full rounded-full object-cover"
                      />
                    </div>
                  ) : (
                    <GalleryAdd size={76} className="text-black text-opacity-70" />
                  )}
                </div>
                <div className="p-2 w-full">
                  <Text variant="title_one" as="div" className="mb-2 text-center truncate">
                    {formik.values.title || "Title"}
                  </Text>
                  <Text variant="subtitle_two" as="div" className="text-center truncate">
                    {formik.values.subtitle || "Subtitle"}
                  </Text>
                </div>
              </Box>
            </div> */}
          </div>
          <div className="flex justify-center flex-wrap gap-2xl">
            <Button
              disabled={formik.isSubmitting}
              type="button"
              variant="secondary"
              className="w-40"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              loaderProps={{ size: "xs" }}
              type="submit"
              className="w-40"
            >
              Add
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
