import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import clientApi from "@/api-client/client";
import { ApiResponse, ApiResponsePagination } from "@/types/api";

import { getQueryKey } from "@key.ai/constants";
import { cleanObject } from "@/lib/helper";
import { Comment } from "@/types/entities";
import eventClientApi from "@/api-client/event-client";

export const useGuestlistGroup = () => {
  const queryKey = getQueryKey("GUESTLIST_GROUP");

  const fetchGroup = async ({ pageParam = 1 }: { pageParam: unknown }) => {
    const { data } = await eventClientApi
      .get(`api/v1/groups`, {
        searchParams: cleanObject({
          page: pageParam as string,
          limit: 10
        })
      })
      .json<ApiResponse<any>>();

    return { ...data, pageParam };
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, isLoading } =
    useInfiniteQuery<ApiResponsePagination<any>>({
      queryKey: [queryKey],
      queryFn: fetchGroup,
      getNextPageParam: (lastPage: any) => {
        if (Math.ceil(lastPage?.count / 10) > lastPage?.pageParam) {
          return lastPage?.pageParam + 1;
        }
        return undefined;
      },
      initialPageParam: 1,
      placeholderData: keepPreviousData
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isLoading
  };
};
