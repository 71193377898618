import { create } from "zustand";

export interface ImageSelectorData {
  img?: File;
  for?: "eventBanner" | "profile" | "homePage" | "calendarBanner" | "homeBasicSection";
  callback?: (args?: any) => void;
  openCroper?: boolean;
  unsplashType?: string;
  cropRatio?: string;
}

interface ImageSelector {
  data: ImageSelectorData;
  isOpen: boolean;
  type: string;
  onOpen: (data?: ImageSelectorData) => void;
  onClose: () => void;
}

export const useImageSelector = create<ImageSelector>((set) => ({
  data: {},
  type: "imageSelector",
  isOpen: false,
  onOpen: (data = {}) => set({ data, isOpen: true }),
  onClose: () => set({ isOpen: false })
}));
