"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getServerDomain = exports.isProtectedRoute = exports.parseTokens = exports.parseContent = exports.parseChannels = exports.parseMentions = exports.getInviteCode = exports.isValidEmail = exports.replaceDynamicParams = exports.replaceUUIDs = void 0;
const constants_1 = require("./constants");
const replaceUUIDs = (string, replacements) => {
    let modified = string;
    Object.keys(replacements).forEach((uuid) => {
        const regexMember = new RegExp("@" + uuid, "g");
        const regexChannel = new RegExp("#" + uuid, "g");
        modified = modified.replace(regexMember, replacements[uuid]);
        modified = modified.replace(regexChannel, replacements[uuid]);
    });
    return modified;
};
exports.replaceUUIDs = replaceUUIDs;
const replaceDynamicParams = (template, params) => {
    if (!params)
        return template;
    Object.keys(params).forEach((placeholder) => {
        const value = params[placeholder];
        const regex = new RegExp(`{${placeholder}}`, "g");
        template = template.replace(regex, value);
    });
    return template;
};
exports.replaceDynamicParams = replaceDynamicParams;
const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
};
exports.isValidEmail = isValidEmail;
const getInviteCode = (length = 15) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    function getRandomCharacter() {
        const randomIndex = Math.floor(Math.random() * characters.length);
        return characters[randomIndex];
    }
    let code = "";
    for (let i = 0; i < length; i++) {
        code += getRandomCharacter();
    }
    return code;
};
exports.getInviteCode = getInviteCode;
const parseMentions = (data) => {
    const mentions = [];
    data.forEach((el) => {
        if (el.insert?.mention?.id &&
            ![constants_1.mentions.channel, constants_1.mentions.everyone].includes(el.insert.mention.id)) {
            mentions.push(el.insert.mention.id);
        }
    });
    return [...new Set(mentions)];
};
exports.parseMentions = parseMentions;
const parseChannels = (data) => {
    const channels = [];
    data.forEach((el) => {
        if (el.insert?.channel?.id) {
            channels.push(el.insert.channel.id);
        }
    });
    return [...new Set(channels)];
};
exports.parseChannels = parseChannels;
const parseContent = (delta) => {
    let content = "";
    for (const d of delta) {
        if (typeof d.insert === "string") {
            content = content.concat(d.insert);
        }
        else {
            if (typeof d.insert.mention === "object") {
                const mention = d.insert.mention;
                content = content.concat(`@${mention.id}`);
            }
            if (typeof d.insert.user === "object") {
                const user = d.insert.user;
                content = content.concat(`@${user.id}`);
            }
            if (typeof d.insert.channel === "object") {
                const channel = d.insert.channel;
                content = content.concat(`#${channel.id}`);
            }
            if (typeof d.insert.emoji === "object") {
                const emoji = d.insert.emoji;
                content = content.concat(`${emoji.native}`);
            }
        }
    }
    return content;
};
exports.parseContent = parseContent;
const parseTokens = (delta) => {
    let tokens = [];
    for (const d of delta) {
        if (d.tokens) {
            tokens.push(...d.tokens);
        }
    }
    return tokens;
};
exports.parseTokens = parseTokens;
const isProtectedRoute = (routes, pathname) => {
    return routes.some((route) => {
        const pattern = route.replace(/\[([^\]]+)\]/g, "[^/]+");
        const regex = new RegExp(`^${pattern}$`);
        return regex.test(pathname);
    });
};
exports.isProtectedRoute = isProtectedRoute;
const getServerDomain = (url) => {
    const baseDomain = process.env.NEXT_PUBLIC_BASE_DOMAIN;
    let hostname;
    try {
        if (!url.startsWith("http"))
            url = "http://" + url;
        hostname = new URL(url).hostname;
    }
    catch (e) {
        return "Invalid URL";
    }
    if (hostname.startsWith("www."))
        hostname = hostname.substring(4);
    if (hostname.endsWith(baseDomain)) {
        return hostname.replace(`.${baseDomain}`, "");
    }
    return hostname;
};
exports.getServerDomain = getServerDomain;
