import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    NEXTAUTH_SECRET: z.string(),
    NEXTAUTH_URL: z.string().url(),
    AUTH_TRUST_HOST: z.string().optional(),
    AUTH_URL: z.string().url(),
    NEXT_PUBLIC_EVENT_API_URL: z.string(),
    APP_CALLBACK_URL: z.string().url(),

    NEXT_PUBLIC_GOOGLE_PLACES_API_KEY: z.string(),

    APPLE_CLIENT_ID: z.string(),
    APPLE_SECRET: z.string(),
    APPLE_CALLBACK_URL: z.string(),

    GOOGLE_CLIENT_ID: z.string(),
    GOOGLE_CLIENT_SECRET: z.string(),

    NODE_TLS_REJECT_UNAUTHORIZED: z.any().optional(),

    API_BASE_URL: z.string(),
    AUTH_API_URL: z.string(),
    EVENT_API_URL: z.string()
  
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */

  client: {
    NEXT_PUBLIC_VAPID_KEY: z.string(),
    NEXT_PUBLIC_FIREBASE_APIKEY: z.string(),
    NEXT_PUBLIC_FIREBASE_AUTHDOMAIN: z.string(),
    NEXT_PUBLIC_FIREBASE_PROJECTID: z.string(),
    NEXT_PUBLIC_FIREBASE_STORAGEBUCKET: z.string(),
    NEXT_PUBLIC_FIREBASE_APPID: z.string(),
    NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID: z.string(),
    NEXT_PUBLIC_FIREBASE_MEASUREMENTID: z.string().optional(),
    NEXT_PUBLIC_FIREBASE_DATABASE_ID: z.string().optional(),

    NEXT_PUBLIC_LIVEKIT_URL: z.string(),
    NEXT_PUBLIC_API_BASE_URL: z.string(),
    NEXT_PUBLIC_AUTH_API_URL: z.string(),
    NEXT_PUBLIC_GIPHY_API_KEY: z.string(),
    NEXT_PUBLIC_PLACEKIT_API: z.string(),
    NEXT_PUBLIC_EVENT_API_URL: z.string(),
    NEXT_PUBLIC_GOOGLE_PLACES_API_KEY: z.string(),
    NEXT_PUBLIC_BASE_DOMAIN: z.string(),
    NEXT_PUBLIC_AUTH_DOMAIN: z.string(),
    NEXT_PUBLIC_APP_DOMAIN: z.string(),
    NEXT_PUBLIC_STRIPE_PUBLIC_KEY: z.string(),

    NEXT_PUBLIC_DATADOG_APPLICATION_ID: z.string(),
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: z.string(),
    NEXT_PUBLIC_DATADOG_DD_SITE: z.string(),
    NEXT_PUBLIC_DATADOG_ENV: z.string(),

    NEXT_PUBLIC_POSTHOG_KEY: z.string(),
    NEXT_PUBLIC_POSTHOG_HOST: z.string(),
    NEXT_PUBLIC_ENV: z.enum(["development", "production", "staging"]).default("development"),

    NEXT_PUBLIC_INTERCOM_API_KEY: z.string(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    AUTH_URL: process.env.AUTH_URL,

    APP_CALLBACK_URL: process.env.APP_CALLBACK_URL,

    NODE_TLS_REJECT_UNAUTHORIZED: process.env.NODE_TLS_REJECT_UNAUTHORIZED,

    NEXT_PUBLIC_LIVEKIT_URL: process.env.NEXT_PUBLIC_LIVEKIT_URL,
    NEXT_PUBLIC_API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
    NEXT_PUBLIC_AUTH_API_URL: process.env.NEXT_PUBLIC_AUTH_API_URL,
    NEXT_PUBLIC_GIPHY_API_KEY: process.env.NEXT_PUBLIC_GIPHY_API_KEY,
    NEXT_PUBLIC_PLACEKIT_API: process.env.NEXT_PUBLIC_PLACEKIT_API,

    NEXT_PUBLIC_VAPID_KEY: process.env.NEXT_PUBLIC_VAPID_KEY,
    NEXT_PUBLIC_FIREBASE_APIKEY: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
    NEXT_PUBLIC_FIREBASE_AUTHDOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
    NEXT_PUBLIC_FIREBASE_PROJECTID: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
    NEXT_PUBLIC_FIREBASE_STORAGEBUCKET: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
    NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID: process.env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID,
    NEXT_PUBLIC_FIREBASE_APPID: process.env.NEXT_PUBLIC_FIREBASE_APPID,
    NEXT_PUBLIC_FIREBASE_MEASUREMENTID: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID,
    NEXT_PUBLIC_FIREBASE_DATABASE_ID: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_ID,
    NEXT_PUBLIC_BASE_DOMAIN: process.env.NEXT_PUBLIC_BASE_DOMAIN,
    NEXT_PUBLIC_AUTH_DOMAIN: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
    NEXT_PUBLIC_APP_DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN,
    NEXT_PUBLIC_EVENT_API_URL: process.env.NEXT_PUBLIC_EVENT_API_URL,
    NEXT_PUBLIC_GOOGLE_PLACES_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,

    APPLE_CLIENT_ID: process.env.APPLE_CLIENT_ID,
    APPLE_SECRET: process.env.APPLE_SECRET,
    APPLE_CALLBACK_URL: process.env.APPLE_CALLBACK_URL,

    GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
    NEXT_PUBLIC_STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,

    NEXT_PUBLIC_DATADOG_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    NEXT_PUBLIC_DATADOG_DD_SITE: process.env.NEXT_PUBLIC_DATADOG_DD_SITE,
    NEXT_PUBLIC_DATADOG_ENV: process.env.NEXT_PUBLIC_DATADOG_ENV,

    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,

    NEXT_PUBLIC_INTERCOM_API_KEY: process.env.NEXT_PUBLIC_INTERCOM_API_KEY,

    API_BASE_URL: process.env.API_BASE_URL,
    AUTH_API_URL: process.env.AUTH_API_URL,
    EVENT_API_URL: process.env.EVENT_API_URL,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: false,
  /**
   * Makes it so that empty strings are treated as undefined. `SOME_VAR: z.string()` and
   * `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true
});
