/* eslint-disable no-unused-vars */
/* eslint-disable turbo/no-undeclared-env-vars */
"use client";

import { useCreateKey } from "@/hooks/use-e2e";
import { useSession } from "next-auth/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const CryptoContext = createContext<{
  publicKey: string;
  privateKey: string;
  privateKeys: string[];
  publicKeys: string[];
  lastSyncVersion: number;
}>({
  publicKey: "",
  privateKey: "",
  privateKeys: [],
  publicKeys: [],
  lastSyncVersion: 0
});

export const useCrypto = () => {
  return useContext(CryptoContext);
};

export const CryptoProvider = ({ children }: { children: React.ReactNode }) => {
  const [publicKey, setPublicKey] = useState<string | null>(null);
  const [privateKey, setPrivateKey] = useState<string | null>(null);
  const [privateKeys, setPrivateKeys] = useState<string[]>([]);
  const [publicKeys, setPublicKeys] = useState<string[]>([]);
  const [lastSyncVersion, setLastSyncVersion] = useState<number>(0);
  const { data: session } = useSession();
  const { doKeysSync } = useCreateKey();
  const token = useMemo(() => session?.accessToken, [session]);
  const userId = useMemo(() => session?.user.id, [session]);

  useEffect(() => {
    if (!token || !userId) return;
    doKeysSync().then((keys) => {
      setPublicKey(keys?.publicKey as string);
      setPrivateKey(keys?.privateKey as string);
      setPrivateKeys(keys?.privateKeys);
      setPublicKeys(keys?.publicKeys);
      setLastSyncVersion(Number(keys?.lastSyncVersion));
    });
    return () => {};
  }, [token, userId]);

  return (
    <CryptoContext.Provider
      value={{
        publicKey: publicKey as string,
        privateKey: privateKey as string,
        privateKeys,
        publicKeys,
        lastSyncVersion
      }}
    >
      {children}
    </CryptoContext.Provider>
  );
};
